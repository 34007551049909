import React from 'react';
import './DataPrivacy.css';

const DataPrivacy = () => {
  return (
    <div className='data-privacy-main-container'>
      <div className='d-p-m-c-inner'>
        <h1>Privacy policy</h1>
        {/* <p>I will send the content per E-Mail</p> */}
        <div>
          <ul>
            <li>
              <h2>1. Which services we use</h2>
              <p>Google AdSense</p>
              <p>Google Ads</p>
              <p>MailerLite</p>
              <p>Meta Ads</p>
              <p>Google Analytics</p>
              <p>Google Tag Manager</p>
              <p>Hotjar</p>
              <p>WhatsApp Business Chat</p>
              <p>Google Fonts API</p>
              <p>Google Maps</p>
              <p>YouTube</p>
              <p>Cookiebot</p>
              <p>Google Sign-in</p>
              <p>Google reCaptcha</p>
              <p>Meta Login</p>
              <p>Google Cloud</p>
            </li>

            <li>
              <h2>2. Contact information</h2>
              <p>
                If you have any questions or concerns about how we protect your data, you can contact us at any time by email at hello@surfpoint.io. Responsible for the data processing that takes place via this website is:
              </p>
              <ul>
                <li>GEWE GmbH</li>
                <li>P.O. Box 583</li>
                <li>3000 Bern 22</li>
                <li>Switzerland</li>
              </ul>
              <p>Person responsible for data protection:</p>
              <ul>
                <li>Andri Werren</li>
                <li>hello@surfpoint.io</li>
                <li>+41 31 589 68 22</li>
              </ul>
            </li>

            <li>
              <h2>3. General principles</h2>
              <ul>
                <li>
                  <h3>3.1 What data do we collect from you and from whom do we receive this data?</h3>
                  <p>First and foremost, we process personal data that you send to us or that we collect when operating our website. We may also receive personal data about you from third parties. This can be the following categories:</p>
                  <p>Personal master data (name, address, date of birth, etc.);</p>
                  <p>Contact data (mobile number, email address, etc.);</p>
                  <p>Financial data (e.g. account details);</p>
                  <p>Online identifiers (e.g. cookie identifiers, IP addresses);</p>
                  <p>Location and traffic data;</p>
                  <p>Sound and image recordings;</p>
                  <p>Data that requires special protection (e.g. biometric data or information about your health).</p>
                </li>
                <li>
                  <h3>3.2 Under what conditions do we process your data?</h3>
                  <p>
                    We treat your data confidentially and in accordance with the purposes set out in this privacy policy. We ensure that processing is transparent and proportionate.
                  </p>
                  <p>
                    If, in exceptional cases, we are unable to follow these principles, data processing may still be lawful because there is a justification. The following may be justifications:
                  </p>
                  <p>Your consent;</p>
                  <p>the execution of a contract or pre-contractual measures;</p>
                  <p>our legitimate interests, provided your interests do not prevail.</p>
                </li>
                <li>
                  <h3>3.3 How can you revoke your consent?</h3>
                  <p>If you have given us consent to process your personal data for certain purposes, we will process your data within the scope of this consent unless we have another justification.</p>
                  <p>You can revoke your consent at any time by sending an email to the address provided in the imprint. Data processing that has already taken place is not affected by this.</p>
                </li>
                <li>
                  <h3>3.4 In which cases can we pass your data on to third parties?</h3>
                  <div>
                    <p>a. Principle</p>
                    <p>
                      We may be dependent on using the services of third parties or affiliated companies and commissioning them to process your data (so-called data processors). Categories of recipients are:
                    </p>
                    <p>Accounting, trust and auditing companies;</p>
                    <p>Consulting companies (legal advice, taxes, etc.);</p>
                    <p>IT service providers (web hosting, support, cloud services, website design, etc.);</p>
                    <p>Payment service providers;</p>
                    <p>Providers of tracking, conversion and advertising services.</p>
                    <p>We ensure that these third parties and our affiliated companies comply with the data protection requirements and treat your personal data confidentially.</p>
                    <p>We may also be obliged to disclose your personal data to authorities.</p>
                  </div>
                  <div>
                    <p>b. Visiting our social media channels</p>
                    <p>
                      We may have embedded links to our social media channels on our website. This is visible to you in each case (typically via corresponding symbols). If you click on the symbols, you will be redirected to our social media channels.
                    </p>
                    <p>In this case, the social media providers will know that you are accessing their platform from our website. The social media providers can use the data collected in this way for their own purposes. We would like to point out that we do not receive any knowledge of the content of the data transmitted or how it is used by the operators.</p>
                  </div>
                  <div>
                    <p>c. Transfer abroad</p>
                    <p>
                      In certain circumstances, your personal data may be transferred to companies abroad as part of the order processing. These companies are obliged to protect data to the same extent as we are. The transfer can take place worldwide.
                    </p>
                    <p>
                      If the level of data protection does not correspond to that of Switzerland, we carry out a prior risk assessment and contractually ensure that the same level of protection is guaranteed as in Switzerland (e.g. by means of the new standard contractual clauses of the EU Commission or other legally prescribed measures). If our risk assessment is negative, we take additional technical measures.
                    </p>
                    <p>
                      Measures to protect your data. You can access the EU Commission's standard contractual clauses at the following link. https://commission.europa.eu/publications/standard-contractual-clauses-controllers-and-processors-eueea_de
                    </p>
                  </div>
                </li>
                <li>
                  <h3>3.5 How long do we keep your data?</h3>
                  <p>We only store personal data for as long as is necessary to fulfill the individual purposes for which the data was collected.</p>
                  <p>Data that we store when you visit our website is stored for twelve months. An exception applies to analysis and tracking data, which can be stored for longer.</p>
                  <p>
                    We store contract data for longer because we are required to do so by law. In particular, we must keep business communications, concluded contracts and booking documents for up to 10 years. If we no longer need such data from you to carry out the services, the data will be blocked and we will only use it for accounting and tax purposes.
                  </p>
                </li>
                <li>
                  <h3>3.6 How do we protect your data?</h3>
                  <p>We will keep your data safe and take all reasonable measures to protect your data from loss, access, misuse or alteration.</p>
                  <p>
                    Our contractual partners and employees who have access to your data are obliged to comply with data protection regulations. In some cases it will be necessary for us to forward your inquiries to companies affiliated with us. Even in these cases, your data will be treated confidentially.
                  </p>
                  <p>
                    On our website we use the SSL (Secure Socket Layer) process in conjunction with the highest level of encryption supported by your browser.
                  </p>
                </li>
                <li>
                  <h3>3.7 What rights do you have?</h3>
                  <div>
                    <p>a. Right to information</p>
                    <p>You can request information about the data we have stored about you at any time. We ask you to send your request for information together with proof of identity to hello@surfpoint.io.</p>
                    <p>You also have the right to receive your data in a common file format if we process your data automatically and if:</p>
                    <p>You have given your consent for this data to be processed; or</p>
                    <p>you have disclosed data in connection with the conclusion or execution of a contract.</p>
                    <p>We can restrict or refuse to provide information or data if this conflicts with our legal obligations, our own legitimate or public interests or the interests of a third party.</p>
                    <p>The processing of your request is subject to the statutory processing period of 30 days. However, we may extend this period due to a high volume of requests, for legal or technical reasons or because we require more detailed information from you. You will be informed of the extension in good time, at least in text form.</p>
                  </div>
                  <div>
                    <p>b. Deletion and correction</p>
                    <p>You have the option of requesting the deletion or correction of your data at any time. We can reject the request if legal regulations require us to store the data for a longer period or unchanged or if a legal basis conflicts with your request.</p>
                    <p>Please note that the exercise of your rights may conflict with contractual agreements and may have corresponding effects on the execution of the contract (e.g. early termination of the contract or cost consequences).</p>
                  </div>
                  <div>
                    <p>c. Legal recourse</p>
                    <p>If you are affected by the processing of personal data, you have the right to enforce your rights in court or to file a report with the responsible supervisory authority. The responsible supervisory authority in Switzerland is the Federal Data Protection and Information Commissioner: https://www.edoeb.admin.ch</p>
                  </div>
                </li>
                <li>
                  <h3>3.8 Changes to the data protection declaration</h3>
                  <p>We can change this data protection declaration at any time. The changes will be published on https://surfpoint.io; you will not be informed separately about them.</p>
                </li>
              </ul>
            </li>
            <li>
              <h2>4. Individual data processing operations</h2>
              <ul>
                <li>
                  <h3>4.1 Providing the website and creating log files</h3>
                  <p>What information do we receive and how do we use it?</p>
                  <p>When you visit <a href='https://surfpoint.io'>https://surfpoint.io</a>, certain data is automatically stored on our servers or on servers of services and products that we purchase and/or have installed for the purposes of system administration, for statistical or backup purposes or for tracking purposes. This includes:</p>
                  <p>the name of your Internet service provider;</p>
                  <p>your IP address (if applicable);</p>
                  <p>the version of your browser software;</p>
                  <p>the operating system of the computer used to access the URL;</p>
                  <p>the date and time of access;</p>
                  <p>the website from which you visit the URL;</p>
                  <p>the search terms you used to find the URL.</p>
                  <p>Why are we allowed to process this data?</p>
                  <p>This data cannot be assigned to a specific person and this data is not merged with other data sources. The log files are stored to guarantee the functionality of the website and to ensure the security of our information technology systems. This is our legitimate interest.
                  </p>
                  <p>How can you prevent data collection?</p>
                  <p>
                    The data is only stored for as long as it is necessary to achieve the purpose of its collection. Accordingly, the data is deleted after each session. The storage of the log files is essential for the operation of the website, so you have no option to object to this.
                  </p>
                </li>
                <li>
                  <h3>4.2 Google AdSense</h3>
                  <p>We use Google AdSense on our website, a service for integrating advertisements from Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google").</p>
                  <p>Google AdSense uses cookies to analyze how visitors use the website. In addition, Google AdSense also uses so-called web beacons (invisible graphics). These web beacons can be used to evaluate information such as visitor traffic on the pages of this service.</p>
                  <p>The information generated by cookies and web beacons about the use of this website (including the IP address of the user) and the delivery of advertising formats is transmitted to a Google server in the USA and stored there. This information may be passed on by Google to Google's contractual partners. However, Google will not combine your IP address with other data stored by you.</p>
                  <p>You can prevent the installation of cookies by setting your browser software accordingly; however, we would like to point out that in this case you may not be able to use all functions of our website to their full extent.</p>
                  <p>You can also deactivate interest-based ads on Google and interest-based Google ads on the web (within the Google Display Network) in your browser by activating the "Off" button at https://www.google.com/settings/ads or deactivating them at https://www.aboutads.info/choices/. You can find more information about your setting options and data protection at Google at https://www.google.com/intl/de/policies/privacy/?fg=1.</p>
                </li>
                <li>
                  <h3>4.3 Google Ads</h3>
                  <p>We use the Google Ads service on our website, an online advertising service provided by Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (hereinafter "Google"). Google Ads enables us to place advertisements in Google search results and in the Google advertising network.</p>
                </li>
                <li>
                  <h3>4.4 MailerLite</h3>
                  <p>To send newsletters and email notifications, we use the MailerLite service, offered by MailerLite Limited, a company registered in Ireland with its registered office at 38 Mount Street Upper, Dublin 2, D02 PR89 Ireland.</p>
                  <p>If you sign up for our newsletter or fill out other forms associated with MailerLite, the data you provide, such as your email address, your name and, if applicable, other information, will be transferred to MailerLite and stored there. This enables us to send you regular updates, offers and other information.</p>
                  <p>
                    By registering for the newsletter or filling out forms, your IP address and the date of registration or submission are also stored. This storage serves as proof in the event that a third party misuses an email address and registers or requests information without the knowledge of the authorized person.
                  </p>
                  <p>MailerLite offers us extensive analysis options on how the newsletters sent are opened and used. These analyses are group-related and are not used by us for individual evaluation.</p>
                  <p>The data stored at MailerLite will be deleted as soon as you unsubscribe from our newsletter or revoke your consent to storage. This does not affect data that we store for other purposes.</p>
                  <p>
                    For more information on data protection at MailerLite, see MailerLite's privacy policy:
                    <a href='https://www.mailerlite.com/legal/privacy-policy'>
                      https://www.mailerlite.com/legal/privacy-policy.
                    </a>
                  </p>
                </li>
                <li>
                  <h3>4.5 Meta Ads</h3>
                  <p>Our website uses Meta Ads, an online advertising service from Meta Platforms, Inc., 1601 Willow Road, Menlo Park, CA 94025, USA (formerly known as Facebook, Inc.). With the help of Meta Ads, we can place targeted advertisements to make users aware of our products or services.</p>
                  <p>When you visit our website, Meta Ads can place a cookie on your device, which enables an analysis of your use of the website. In addition, Meta can use this cookie to track your usage behavior across different websites and devices and place personalized advertisements on this basis.</p>
                  <p>The data collected by the cookie is transmitted to Meta and stored there. Meta can link this data to your Meta account (formerly Facebook account) and use it for its own advertising purposes.</p>
                  <p>To generally object to the use of cookies on your computer, you can set your Internet browser so that no more cookies can be stored on your computer in the future or cookies that have already been stored are deleted. However, disabling cookies may result in some functions of our website no longer working properly.</p>
                  <p>For more information on how Meta Ads handles user data, see Meta's privacy policy: https://www.facebook.com/privacy/policy/. If you do not want Meta to use data for advertising purposes, you can adjust the relevant settings in your Meta account.</p>
                </li>
                <li>
                  <h3>4.6 Google Analytics</h3>
                  <p>We use Google Analytics, a web analysis service provided by Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google") on our website. Google Analytics uses so-called "cookies", text files that are stored on your computer and that enable an analysis of your use of the website.</p>
                  <p>The information generated by the cookie about your use of this website is usually transferred to a Google server in the USA and stored there. However, if IP anonymization is activated on this website, your IP address will be shortened beforehand by Google within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there.</p>
                  <p>
                    On behalf of the operator of this website, Google will use this information to evaluate your use of the website, to compile reports on website activity and to provide the website operator with other services relating to website activity and internet usage.
                  </p>
                  <p>The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data.</p>
                  <p>
                    You can prevent cookies from being saved by setting your browser software accordingly; however, we would like to point out that in this case you may not be able to use all functions of this website to their full extent. You can also prevent Google from collecting the data generated by the cookie and relating to your use of the website (including your IP address) and from processing this data by Google by downloading and installing the browser plug-in available under the following link: https://tools.google.com/dlpage/gaoptout.
                  </p>

                </li>
                <li>
                  <h3>4.7 Google Tag Manager</h3>
                  <p>We use Google Tag Manager, a service provided by Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"), to manage website tags via an interface. Google Tag Manager itself (which implements the tags) is a cookie-free domain and does not process any personal data. The service triggers other tags, which in turn may collect data. Google Tag Manager does not access this data. If deactivation has been carried out at domain or cookie level, this remains in place for all tracking tags implemented with Google Tag Manager.</p>
                  <p>
                    Using Google Tag Manager can automatically activate scripts (so-called tags) on our website and transmit data from your browser to Google. This data can include information about your IP address, the browser you use, the subpages of our website you visit, and other interactions during your website visit.
                  </p>

                </li>
                <li>
                  <h3>4.8 Hotjar</h3>
                  <p>We use the web analysis service Hotjar from Hotjar Ltd on our website. Hotjar Ltd. is a European company based in Malta (Hotjar Ltd, Level 2, St Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 1000, Malta, Europe). Hotjar enables us to measure and analyze the behavior of our website visitors.</p>
                  <p>With Hotjar, for example, we can determine how long users stay on a page, which links they click on and how they move through the page. This helps us to make our website more user-friendly. The information is collected anonymously and not merged with other data.</p>
                  <p>Hotjar uses cookies and other technologies to collect information about the behavior of our users and their devices. This can be the IP address of the device (only stored in anonymized form), screen size, device type, browser information, geographic location (country only) and preferred language.</p>

                </li>
                <li>
                  <h3>4.9 WhatsApp Business Chat</h3>
                  <p>For direct communication, we offer you the opportunity to contact us via "WhatsApp Business Chat". This service is provided by WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland, a subsidiary of Meta Inc., 1601 Willow Road, Menlo Park, CA 94025, USA.</p>
                  <p>If you contact us via WhatsApp Business Chat, your mobile phone number and the content of your message, as well as any other data you provide, will be transmitted to WhatsApp and processed there. We receive this data and use it to respond to your request.</p>
                  <p>WhatsApp uses cookies and other technologies to provide the service and enable communication. Your IP address may also be recorded and stored.</p>
                  <p>Please note that the use of WhatsApp Business Chat and communication via this service is subject to WhatsApp's privacy policy. We recommend that you read these policies before using the service.</p>

                </li>
                <li>
                  <h3>4.10 Google Fonts API</h3>
                  <p>On our website we use the Google Fonts API, a service of Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"), to integrate fonts. By integrating these fonts, your browser will establish a connection to Google's servers when you visit our website in order to download the fonts and display them correctly.</p>
                  <p>Through this connection, data, in particular your IP address and information about the browser you use, can be transmitted to Google and stored on Google servers. These servers may be located in the USA or other countries.</p>
                  <p>If you want to prevent the transmission of data as part of the Google Fonts API, you can block access to the domain fonts.googleapis.com in your browser settings. Please note that in this case our website may not be displayed correctly.</p>
                </li>
                <li>
                  <h3>4.11 Google Maps</h3>
                  <p>On our website we use Google Maps, a map service provided by Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). Google Maps enables us to integrate interactive maps directly into the website and enables you to conveniently use the map function, for example to search for a location or to plan your route.</p>
                  <p>When you visit the website, Google receives the information that you have accessed the corresponding subpage of our website. In addition, other data, in particular your IP address, is transmitted to Google and stored on Google servers. These servers may be located in the USA or other countries. This occurs regardless of whether Google provides a user account through which you are logged in or whether no user account exists.</p>
                  <p>Google stores your data as usage profiles and uses them for the purposes of advertising, market research and/or needs-based design of its website. Such an evaluation is carried out in particular (even for users who are not logged in) to provide needs-based advertising.</p>
                  <p>You have the right to object to the creation of these user profiles, and you must contact Google to exercise this right.</p>
                </li>
                <li>
                  <h3>4.12 YouTube</h3>
                  <p>Our website includes videos from YouTube, a platform of Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). When you view the videos or click the play button, data, including your IP address and information about the browser you are using, is transmitted to Google servers and stored there. This data is used to provide the video, monitor performance and improve the user experience.</p>
                  <p>If you are logged into your YouTube account, you allow YouTube to assign your surfing behavior directly to your personal profile. You can prevent this by logging out of your YouTube account.</p>
                </li>
                <li>
                  <h3>4.13 Cookiebot</h3>
                  <p>Our website uses Cookiebot, a cookie consent and compliance service provided by Cybot A/S. Cookiebot helps us obtain and manage our users' consent for cookies and online tracking in accordance with legal requirements.</p>
                  <p>When you visit our website, Cookiebot may collect information about your consent choices and your interaction with the consent pop-up. This information is used to ensure that only the cookies and tracking technologies you approve of are activated on your device.</p>
                </li>
                <li>
                  <h3>4.14 Google Sign-in</h3>
                  <p>On our website we offer the option to log in via Google Sign-in, a service provided by Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). Google Sign-in allows you to log in to our website using your Google account without having to create a separate username and password for our website.</p>
                  <p>When you sign in using Google Sign-in, Google will ask you to grant certain permissions that allow us to access selected information in your Google Account. This may include your name, email address, and profile picture. This information is used to create or update your user profile on our website.</p>
                  <p>By using Google Sign-in, data, including the permissions you grant and your IP address, is transmitted to Google and stored on Google servers. These servers may be located in the United States or other countries.</p>
                  <p>If you do not want Google to directly associate the data collected via our web service with your Google Account, you cannot sign in to our website using Google Sign-in.</p>
                </li>
                <li>
                  <h3>4.15 Google reCaptcha</h3>
                  <p>To protect against unwanted requests via the internet form on our website, we use the reCAPTCHA service provided by Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). The query is used to distinguish whether the input was made by a human or abusively by automated, machine processing. As part of the query, information such as your IP address or the behavior when filling out the form can be transmitted to Google.</p>
                  <p>For this purpose, your input is transmitted to Google and processed there. By using reCAPTCHA, you agree that the recognition you provide will be included in the digitization of old works. However, if IP anonymization is activated on this website, your IP address will be shortened beforehand by Google within member states of the European Union or in other contracting states to the Agreement on the European Economic Area.</p>
                  <p>Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. On behalf of the operator of this website, Google will use this information to evaluate your use of this service. The IP address transmitted by your browser as part of reCAPTCHA will not be merged with other Google data.</p>
                </li>
                <li>
                  <h3>4.16 Meta Login</h3>
                  <p>Our website offers the option of logging in using Meta Login. This is a service provided by Meta Platforms, Inc., 1601 Willow Road, Menlo Park, CA 94025, USA (formerly known as Facebook, Inc.). By using Meta Login, you can register and log in to our website using your Meta user data. This saves you the need to create a separate user account on our website.</p>
                  <p>When you log in to us via Meta Login, you allow Meta to share certain information from your Meta profile with us. This may include your name, email address, profile picture, public Meta ID and other data you share publicly on Meta. Exactly which data is transmitted depends on your personal privacy settings at Meta.</p>
                  <p>We use this data to create and personalize your user account, to give you access to our platform and to ensure that you can log in via Meta Login in the future. The data will not be used for any other purpose without your express consent.</p>
                  <p>For more information on how user data is handled in connection with Meta Login, please see Meta's privacy policy: https://www.facebook.com/privacy/policy/. If you do not want Meta to share data with us, please do not use the Meta Login function on our website.</p>
                </li>
                <li>
                  <h3>4.17 Google Cloud</h3>
                  <p>For storage, hosting and data processing services, we use Google Cloud, a cloud platform service provided by Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). Google Cloud enables us to store data</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DataPrivacy