import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import "./CookieConsent.css";

const CookieConsent = () => {

  const [cookies, setCookies] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookies("cookieConsent", true, { path: "/" })
  };

  return (
    <div className="cookie-consent">
      <p>
        We use cookies to enhance your user experience. By using our website,
        you agree to our use of cookies.{" "}
        <a href={"/privacypolicy"}>Learn more.</a>
      </p>
      <button onClick={giveCookieConsent}>
        Accept
      </button>
    </div>
  )
}

export default CookieConsent