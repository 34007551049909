import { NavLink } from 'react-router-dom';
import SurfPointLogo from '../../assets/images/logo_surfpoint-2.svg';
import './Logo.css';

const Logo = () => {
  return (
    <div className='logo-container'>
      <NavLink to={"/"}>
        <img src={SurfPointLogo} alt='logo' />
      </NavLink>
    </div>
  )
}

export default Logo;