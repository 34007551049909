// Seller Profile Page 
import './SellerProfilePage.css';
import {
  ProfileViewer,
  SearchIcon,
  SellerProfilePagePV,
  Sidebar
} from '../../components';

const SellerProfilePage = () => {
  return (
    <div className='content-body' style={{ marginTop: '6rem' }}>

      <div className='seller-profile-p-v'>
        <SellerProfilePagePV />
      </div>

      <div className='sp-page-fr-container'>
        <div className='flex-container'>
          <div className='a-container'>
            <div></div>
            <p>Selling</p>
          </div>
          <div className='a-container'>
            <div></div>
            <p>Sold</p>
          </div>
          <div className='a-container'>
            <div></div>
            <p>Likes</p>
          </div>
          <div className='a-container'>
            <div></div>
            <p>Saved</p>
          </div>
        </div>
      </div>

      <div className='sp-page-sr-container'>
        <div className='flex-container'>
          <div className='a-container'>
          </div>
          <div className='a-container'>
          </div>
          <div className='a-container'>
          </div>
        </div>
      </div>

      <div className='sp-page-tr-container sp-page-tr-container-desktop'>
        <div className='flex-container'>
          <div className='a-container'>
          </div>
          <div className='a-container'>
          </div>
          <div className='container-c'>
            <div className='container-a'></div>
            <div className='container-a'></div>
            <div className='container-a'></div>
          </div>
        </div>
      </div>

      <div className='sp-page-tr-container sp-page-tr-container-mobile'>
        <div className='a-container'>
        </div>
        <div className='flex-container'>
          <div className='a-container'>
          </div>
          <div className='container-c'>
            <div className='container-a'></div>
            <div className='container-a'></div>
            <div className='container-a'></div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default SellerProfilePage