import React from "react"
import { Link } from "react-router-dom"

const HeroSectionBtn = () => {
  return (
    <div className='sell-and-explore-btn'>
      <Link to="/signup"><button className='s-btn'>Register now</button></Link>
    </div>
  )
}

export default HeroSectionBtn