import { useEffect, useState, useContext } from 'react';
import Checkbox from '../checkbox/Checkbox';
import ChooseLocation from '../chooseLocation/ChooseLocation';
import './Filter.css';
import SizeRange from './sizeRange/SizeRange';
import api from '../../utils/api';
import {
  MapWithSearch,
} from '../../components';
import { MyContext } from '../../pages/productListingPage/MyContext';
import Select from 'react-select';

const ClearIndicator = (props) => {
  const {
    children = '×',
    className = ' css-1xc3v61-indicatorContainer',
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.clearValue();
      }}
      style={getStyles('clearIndicator', props)}
    >
      {children}
    </div>
  );
};

const ClearIndicator2 = (props) => {
  const {
    children = '×',
    className = ' css-1xc3v61-indicatorContainer',
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.clearValue();
      }}
      style={getStyles('clearIndicator', props)}
    >
      {children}
    </div>
  );
};

const Filter = ({  }) => {

  const { filterInputs, setFilterInputs, brands, locations } = useContext(MyContext);

 
  const [minSizeFeet, setMinSizeFeet] = useState(5);
  const [minSizeInch, setMinSizeInch] = useState(0);
  const [minSizeLabel, setMinSizeLabel] = useState("");


  const [maxSizeFeet, setMaxSizeFeet] = useState(10);
  const [maxSizeInch, setMaxSizeInch] = useState(0);
  const [maxSizeLabel, setMaxSizeLabel] = useState("");

  const updateLocation = (locationVal) => {
    let keyName = 'location';
    //updateFilter(keyName, locationVal);
   // setFilterInputs({ ...filterInputs, [keyName]: locationVal })
  }

  const minSizeHandleChange = (event) => {
    const value = parseInt(event.target.value);
    const maxvalue = (maxSizeFeet * 12 + maxSizeInch);
    if(value < maxvalue) {
      setMinSizeFeet(Math.floor(value / 12));
      setMinSizeInch(value % 12);
      setFilterInputs({ ...filterInputs, ['min_size']: value })
    }
    
  };

  const maxSizeHandleChange = (event) => {
    const value = parseInt(event.target.value);
    const minvalue = (minSizeFeet * 12 + minSizeInch);
    if(value > minvalue) {
      setMaxSizeFeet(Math.floor(value / 12));
      setMaxSizeInch(value % 12);
      setFilterInputs({ ...filterInputs, ['max_size']: value })
    }
  };

  const handleChange = (event, keyName) => {
    let val = event.target.value;
    setFilterInputs({ ...filterInputs, [keyName]: val });
    console.log(filterInputs);
  };

  


  // useEffect(() => {
  //   const fetchBrands = async () => {
  //     const response = await api.get("/brands/");
  //      if(response.data.length > 0) {
  //        let options = await response.data.map((x) => { return { brand_name: x.brand_name, key: x.id } });
  //        console.log(options);
  //        setBrands(options);
  //      }
      
  //   };
  //   fetchBrands();
  // }, []);

  const [selectedOptions, setSelectedOptions] = useState(filterInputs.brand_ids);

  const handleChangeBrands = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedOptions(selectedOptions);
    setFilterInputs({ ...filterInputs, ['brand_ids']: selectedOptions })
  };
  

  const [selectedLocationOptions, setSelectedLocationOptions] = useState([filterInputs.location]);

  const handleChangeLocations = (selectedOptions) => {
    setSelectedLocationOptions(selectedOptions);
    setFilterInputs({ ...filterInputs, ['location']: selectedOptions })
  };
  


  return (
    
    <>
      
      {/* Filter Page First Column */}
      <div className='f-p-c-c-first-column'>

        <div className='fpccfc-price-range'>
          <div className='range-inputs-container'>
            <div className='filter-page-lable-of-input'>Price Range</div>
            <div className='r-i-c-inputs'>
              <input
                type='text'
                placeholder={'min. price $'}
                value={filterInputs['min_price']}
                onChange={(event) => handleChange(event, 'min_price')}
              />
              <input
                type='text'
                placeholder={'max. price $'}
                value={filterInputs['max_price']}
                onChange={(event) => handleChange(event, 'max_price')}
              />
            </div>
          </div>
        </div>

        <div className='fpccfc-size-range'>
       
          <SizeRange min={60}
            max={120} minSizeFeet={minSizeFeet}
            minSizeInch={minSizeInch} maxSizeFeet={maxSizeFeet}
            maxSizeInch={maxSizeInch} minSizeLabel={minSizeLabel} maxSizeLabel={maxSizeLabel} minSizeHandleChange={(e) => minSizeHandleChange(e)} maxSizeHandleChange={(e) => maxSizeHandleChange(e)} />
        </div>

        

        {/* <div className='fpccfc-choose-material'>
          <div className='choose-material-container'>
            <div className='filter-page-lable-of-input'>Choose Material</div>
            <div className='materials-row'>
              {
                material_values.map((item, index) => {
                  return (
                    <div key={index} className={material === item ? 'active' : ''}>
                      <label>
                        <input
                          type='radio'
                          onClick={(event) => {
                            setMaterial(item);
                            handleChange(event, 'material');
                          }}
                          name='materil'
                          value={item}
                        />
                        {item}
                      </label>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div> */ }

        <div className='fpccfc-volume-range'>
          <div className='range-inputs-container'>
            <div className='filter-page-lable-of-input'>Volume Range</div>
            <div className='r-i-c-inputs'>
              <input
                type='text'
                placeholder={'min. volume'}
                value={filterInputs['min_volume']}
                onChange={(event) => handleChange(event, 'min_volume')}
              />
              <input
                type='text'
                placeholder={'max. volume'}
                value={filterInputs['max_volume']}
                onChange={(event) => handleChange(event, 'max_volume')}
              />
            </div>
          </div>
        </div>

        { /*  <div className='fpccfc-thickness-range'>
          <div className='range-inputs-container'>
            <div className='filter-page-lable-of-input'>Thickness Range</div>
            <div className='r-i-c-inputs'>
              <input
                type='text'
                placeholder={'min. thickness'}
                value={filterInputs['min-thickness']}
                onChange={(event) => handleChange(event, 'min-thickness')}
              />
              <input
                type='text'
                placeholder={'max. thickness'}
                value={filterInputs['max-thickness']}
                onChange={(event) => handleChange(event, 'max-thickness')}
              />
            </div>
          </div>
        </div>

        <div className='fpccfc-width-range'>
          <div className='range-inputs-container'>
            <div className='filter-page-lable-of-input'>Width Range</div>
            <div className='r-i-c-inputs'>
              <input
                type='text'
                placeholder={'min. width'}
                value={filterInputs['min-width']}
                onChange={(event) => handleChange(event, 'min-width')}
              />
              <input
                type='text'
                placeholder={'max. width'}
                value={filterInputs['max-width']}
                onChange={(event) => handleChange(event, 'max-width')}
              />
            </div>
          </div>
        </div> */}

      </div>

  


      {/* Filter Page Second Column */}
      <div className='f-p-c-c-second-column'>
      <div className='fpccfc-choose-brand'>
          <div className='choose-brand-container'>
            <div className='filter-page-lable-of-input'>Select Location</div>
            <div className='c-b-c-dropdown'>
            <Select

        value={selectedLocationOptions}
        onChange={handleChangeLocations}
        isClearable
        options={locations}
        components={{ ClearIndicator }}
        
      />
              
            </div>
          </div>
        </div>
        <div className='fpccfc-choose-brand'>
          <div className='choose-brand-container'>
            <div className='filter-page-lable-of-input'>Choose Brand</div>
            <div className='c-b-c-dropdown'>
            <Select
       
        value={selectedOptions}
        onChange={handleChangeBrands}
        isClearable
        options={brands}
        isMulti={true}
        components={{ ClearIndicator }}
        
      />
                
        
            </div>
          </div>
        </div>
      

        {/* <div className='fpccsc-choose-location'>
        <MapWithSearch updateLocation={locationVal => updateLocation(locationVal)} />
          <div className='clcmc-map-range'>
            <div className='label'>Range: 6 km</div>
            <input type='range' />
          </div>
        </div> */}

        {/* <div className='fpccsc-whats-included'>
          <div className='whats-included-container'>
            <div className='filter-page-lable-of-input'>Whats Included</div>
            <div className='wic-checkboxes'>
              <Checkbox
                name="Fin"
                value="Yes"
                checked={filterInputs.is_fin === "Yes" ? 'checked' : ''}
                onClick={(event) => handleChange(event, 'is_fin')}
              />
              <Checkbox 
                name={'Leash'} 
                value="Yes"
                checked={filterInputs.is_leash === "Yes" ? 'checked' : ''}
                onClick={(event) => handleChange(event, 'is_leash')}
              />
              <Checkbox 
                name={'Surf-Sock'}
                value="Yes"
                checked={filterInputs.is_surf_stock === "Yes" ? 'checked' : ''}
                onClick={(event) => handleChange(event, 'is_surf_stock')}
              />
              <Checkbox 
                name={'Board-Bag'}
                value="Yes"
                checked={filterInputs.is_board_bag === "Yes" ? 'checked' : ''}
                onClick={(event) => handleChange(event, 'is_board_bag')}
              />
            </div>
          </div>
        </div>

        <div className='fpccsc-surf-level'>
          <div className='surf-level-container'>
            <div className='filter-page-lable-of-input'>Surf Level</div>
            <div className='materials-row'>
              {
                level_of_surf_values.map((item, index) => {
                  return (
                    <div key={index} className={surfLevel === item ? 'active' : ''}>
                      <label>
                        <input
                          type='radio'
                          onClick={(event) => {
                            setSurfLevel(item);
                            handleChange(event, 'surf-level');
                          }}
                          name='surf-level'
                          value={item}
                        />
                        {item}
                      </label>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className='fpccsc-fin-setup'>
          <div className='surf-level-container'>
            <div className='filter-page-lable-of-input'>Fin-Setup</div>
            <div className='materials-row'>
              {
                fit_setup_values.map((item, index) => {
                  return (
                    <div key={index} className={finSetup === item ? 'active' : ''}>
                      <label>
                        <input
                          type='radio'
                          onClick={(event) => {
                            setFinSetup(item);
                            handleChange(event, 'fin-setup');
                          }}
                          name='fin-setup'
                          value={item}
                        />
                        {item}
                      </label>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div> */}


      </div>

    </>
  )
}

export default Filter