import './ProductSection.css';
import { FaEuroSign } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import api from '../../../utils/api';
import { IoIosArrowForward } from "react-icons/io";
import { imageUrl } from '../../../utils/local-store';
import defaultImg from '../../../assets/images/default-img.jpg';

const ProductSection = () => {

  const [products, setProducts] = useState([]);
  const [productLoop, setProductLoop] = useState(0);
  useEffect(() => {
    
    const fetchProducts = async () => {
      try{
        const response = await api.post("products/getProducts", {
          page: 1, 
          limit: 18
        });
        if(response !== undefined) {
          let dataLength = response.data.length;
          if(dataLength < 3) {
            setProductLoop(dataLength);
          } else {
            setProductLoop(dataLength % 3 > 0?parseInt(dataLength / 3) * 3:dataLength);
          }          
          console.log(response.data.length);
          setProducts(response.data);
        }
      } catch(error) {
        console.log(error);
      }
       
     
    };
  
    fetchProducts();
  }, []);
 
  return (
    <>
   <div className='product-section-row'>
    
      {/* Product 1 */
      
        products && products.map((item, index) => {
          console.log(productLoop);
        if(index >= productLoop) {
          return
        }
        let ProductImages = products[index].ProductImages;
        let hppImage = ProductImages.length > 0?imageUrl + ProductImages[0].image_name:defaultImg;
        let description = item.description.substring(0, 50);

        return(
 
        
          <div className='p-s-r-a-product'>

<div className='p-s-r-a-product-img'>
<NavLink to={"/login"} ><img src={hppImage} alt='product.png' /> </NavLink>
</div>

<div className='p-s-r-a-product-text'>
  <div className='p-s-r-a-p-price'>
    <span>{item.price}</span>
    <FaEuroSign />
  </div>

  <div className='p-s-r-a-p-name'>
    <div className='p-n'><NavLink to={"/login"} >{item.name}</NavLink></div>
    <div className='a-i'><NavLink to={"/login"} ><IoMdArrowForward /></NavLink> </div>
  </div>

  <div className='p-s-r-a-p-description' >
    {description}
  </div>

  <div className='p-s-r-a-p-details'>
    <div className='next-arrow-btn'>
      <IoIosArrowForward />
    </div>
    <div className='p-d-wrapper'>
      <div className='p-d-a-detail'>{(new Date()).getFullYear() - item.year_of_pur} Year old</div>
      <div className='p-d-a-detail'>Size: {item.size.replace('@','\'')}</div>
      <div className='p-d-a-detail'>Volume: {item.volume} Liters</div>
      <div className='p-d-a-detail'>Width: {item.width.replace('_', ' ')}</div>
      <div className='p-d-a-detail'>Material: {item.material}</div>
    </div>
  </div>

</div>

</div>
        
        );
        })

      }
      </div>
    </>
  )
}

const  getContainerDiv = (isOpen) => {
    return isOpen?"<div className='product-section-row'>":"</div>"
};


export default ProductSection