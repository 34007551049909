import React from 'react';
import './GoBackBtn.css';
import { useNavigate  } from "react-router-dom";
import { BiSolidArrowFromRight } from "react-icons/bi";


const GoBackBtn = () => {
   let navigate = useNavigate();
  return (
    <>
      <button className='go-back-btn' onClick={() => navigate(-1)}>
        <BiSolidArrowFromRight />
        <span>Go back to Marketplace</span>
      </button>
    </>

  )
}

export default GoBackBtn