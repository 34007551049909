import React from 'react';
import "./Saved.css";
import { DashboardProfileViewer } from '../../components';

const Saved = () => {
  return (
    <div className='saved-container'>
      <div className='content-body'>

      <div className='dashboard-p-v'>
        <DashboardProfileViewer />
      </div>
      <h2>Saved Surfboards</h2>
      

      </div>
    </div>
  )
}

export default Saved;