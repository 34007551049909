const FilterIcon = () => {
  return (
    <>
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.87187 7.92165L8.87451 7.91768L8.87453 7.9177L12.6154 2.21699C12.6155 2.21686 12.6156 2.21673 12.6157 2.21659C12.7515 2.01185 12.7695 1.74451 12.6638 1.52192L8.87187 7.92165ZM8.87187 7.92165L8.87192 7.92169L8.86902 7.92592C8.75065 8.09879 8.68581 8.30957 8.68539 8.52747C8.68539 8.52751 8.68539 8.52755 8.68539 8.52759L8.68539 13.1753V13.1756H8.53539M8.87187 7.92165L5.7143 12.9283M8.53539 13.1756C8.53618 13.3134 8.48621 13.4456 8.39649 13.5432C8.30678 13.6409 8.1848 13.6957 8.05761 13.6957C7.99288 13.6957 7.92894 13.6817 7.8692 13.6549M8.53539 13.1756V8.52732V13.1756ZM7.8692 13.6549L7.812 13.7936L7.81004 13.7928C7.8093 13.7924 7.80857 13.7921 7.80784 13.7918L7.8692 13.6549ZM7.8692 13.6549L5.76685 12.7877M5.76685 12.7877L5.71985 12.9301C5.71799 12.9295 5.71615 12.9289 5.7143 12.9283M5.76685 12.7877C5.57844 12.7255 5.4534 12.5332 5.4534 12.3017V8.52732L5.7143 12.9283M5.76685 12.7877L5.70965 12.9263L5.7143 12.9283M1.88856 1.15444C1.64571 1.15401 1.43037 1.30056 1.32523 1.52192L1.88856 1.15444ZM1.88856 1.15444H1.88842V1.30444L1.88872 1.15444C1.88867 1.15444 1.88861 1.15444 1.88856 1.15444ZM1.88856 1.15444H12.1006M1.88856 1.15444H12.1006M12.1006 1.15444C12.3433 1.15401 12.5586 1.30057 12.6638 1.5219L12.1006 1.15444ZM6.18509 8.52705V8.52732V12.1249L7.80392 12.7925V8.52732L7.80392 8.52705C7.80466 8.11724 7.92597 7.71729 8.15197 7.3852L11.6311 2.08346H2.35797L5.83717 7.38521C6.06304 7.71729 6.18436 8.11724 6.18509 8.52705Z" fill="white" stroke="white" strokeWidth="0.3" />
        <path d="M5.5 8L2 2L12 1.5L8 8V13L6 12.5L5.5 8Z" fill="white" />
      </svg>
    </>
  )
}

export default FilterIcon;
