import { ProfileIcon } from '../../assets/icons';
import './ChatBoxProfileViewer.css';
import { CiUser } from "react-icons/ci";

const ChatBoxProfileViewer = () => {
  return (
    <>
      <div className='profile-img'>
        <div className='p-i-1'>
          <i>
            <ProfileIcon />
          </i>
        </div>
        <div className='p-i-2'>
          <i>
            <ProfileIcon />
          </i>
        </div>
      </div>
      <div className='profile-text'>
          <h4>Profilname</h4>
          <p>@Profilname</p>
      </div>
    </>
  )
}

export default ChatBoxProfileViewer