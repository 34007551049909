import React from 'react'
import "./AccountSettings.css";
import { ChangePassword, DashboardProfileViewer, ProfileSetting } from '../../components';

const AccountSettings = () => {
  return (
    <div className='account-settings-container'>
      <div className='content-body'>

        <div className='dashboard-p-v'>
          <DashboardProfileViewer />
        </div>

        <div className="a-s-c-content-container">

          <div className='a-s-c-c-c-item'>
            <ProfileSetting />
          </div>

          <hr className='account-setting-hr'/>

          <div className='a-s-c-c-c-item'>
            <ChangePassword />
          </div>
         
        </div>

      </div>
    </div>
  )
}

export default AccountSettings