import './MenuIcon.css'
import { FiMenu } from "react-icons/fi";

const MenuIcon = ( notify = true ) => {
  return (
    <div className='menu-icon-container'>
      <FiMenu />
      { notify && <span className='notify-dot'></span> }
    </div>
  )
}

export default MenuIcon