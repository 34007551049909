import './Buttons.css';
import { RiSendPlaneFill } from "react-icons/ri";

const SendMsgIconBtn = () => {
  return (
    <button type='submit'>
      <span className='send-msg-icon-btn'>
        <RiSendPlaneFill />
      </span>
    </button>
  )
}

export default SendMsgIconBtn;