import './FilterPage.css';
import { FaCheck } from "react-icons/fa6";
import { useState, useContext } from 'react';
import { Filter } from '../../components';
import { MyContext } from '../../pages/productListingPage/MyContext';
import { IoClose } from "react-icons/io5";

const FilterPage = ({ closeModal }) => {

  const { filterInputs, setFilterInputs } = useContext(MyContext);

  const handleFilterClose = () => {
    setFilterInputs({ ...filterInputs, ['isClose']: true, ['isReset']: false });
    closeModal()
  };

  const handleFilterReset = () => {
    setFilterInputs({ "min_price": "",
    "max_price": "",
    "min_size": 60,
    "max_size": 120,
    "brand_ids": [],
    "min_volume": "",
    "max_volume": "",    
    "location": "",
    "isClose": true,
    "isReset": true });
    closeModal()
  };


  return (

    <div className='filter-pop-up-main-container'>

      <div className='filter-page-container-heading'>
        <div className='page-name'>Filter & Sort</div>
        <div className="filter-btns">
          <span className="done-button"  onClick={(e) => handleFilterReset()}><IoClose title='Reset Filter' /></span>
          <span className='done-button close' onClick={(e) => handleFilterClose()}><FaCheck title='Apply Filter' /></span>
        </div>
      </div>

      <div className='filter-page-content-container'>
        <div className='f-p-c-c-inner-div'>

          <Filter />

        </div>
      </div>

    </div>
  )
}

export default FilterPage