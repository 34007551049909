// components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../../auth/actions/authActions'
import api from '../../utils/api';
import showToastMessage from '../toast-message/ToastMessage';
import validateFields from '../../utils/validation';

const Login = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const fields = [
                { value: userName, validationType: 'required', keyName: 'Email' },
                { value: userName, validationType: 'email', keyName: 'Email' },
                { value: password, validationType: 'required', keyName: 'Password' },
            ];
            //   console.log(validateFields(fields)); // Output: true
            if (validateFields(fields)) {
                const response = await api.post('/auth/login', { userName, password });
                console.log(response)
                const { user, accessToken, refreshToken, expiryTime } = response.data;
                dispatch(loginSuccess({ user, accessToken, refreshToken, expiryTime }));
                // Redirect to another page
                navigate('/dashboard'); // Replace '/dashboard' with the desired page path
            }
        } catch (error) {
            console.log(error);
            let errorMessage = error.response.data.message || error.message;
            showToastMessage(errorMessage, "error");
            dispatch(loginFailure());
        }
    };

    return (


        <form className="grid sign-up-form" style={{ gap: '12px', padding: '16px 0' }}>

            <input
                type='text'
                name='userName'
                placeholder='Email'
                value={userName}
                onChange={e => setUserName(e.target.value)}
            />

            <input
                type='password'
                name='password'
                placeholder='Password'
                value={password}
                onChange={e => setPassword(e.target.value)}
            />

            <button
                type='button'
                className='sign-up-btn'
                style={{ marginTop: '6px' }}
                onClick={handleLogin}
            >
                LOGIN
            </button>

        </form>

    );
};

export default Login;
