import { FooterCompo } from '../../components';
import './Footer.css';


const Footer = () => {
  return (
    <footer className='footer-main-container'>
      <div>
        <FooterCompo />
      </div>
    </footer>
  )
}

export default Footer