// Seller Profile Page Profile Veiwer with rating component 
import './SellerProfilePagePV.css';
import StarRating from '../starRating/StarRating';
import {ProfileIcon} from '../../assets/icons';

const SellerProfilePagePV = () => {
  return (
    <>
     <div>
        <div className='user-img-w-r-SPPPV '>
          <i>
            <ProfileIcon />
          </i>
        </div>
        <div className='user-name-w-r-SPPPV'>
          <h4>@Profilename</h4>
          <StarRating rating={4} />
        </div>
      </div>
    </>
  )
}

export default SellerProfilePagePV