// Profile Veiwer component 
import './ProfileViewer.css';
import { IoIosArrowDown } from "react-icons/io";
import {ProfileIcon} from '../../assets/icons';
import { useState, useEffect } from 'react';
import { GetLoggedInUser } from '../../utils/local-store';

const ProfileViewer = () => {
  const [userName, setUserName] = useState('');
  let user = GetLoggedInUser();

  useEffect(() => {
    setUserName(user.name);

  }, [user]);

  return (
    <div className='p-v-container'>
      <div className='user-img'>
        <i>
          {/* <img src={''} alt={''} /> */}
          <ProfileIcon/>
        </i>
      </div>
      <div className='user-name'>
        <p>Welcome,</p>
        <div>
          <h4>{userName}</h4>
          <IoIosArrowDown />
        </div>
        
      </div>
    </div>
  )
}

export default ProfileViewer