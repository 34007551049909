// Story container of chatbox component 
import './ChatBoxStoryContainer.css';
import { ProfileIcon } from '../../assets/icons';

const ChatBoxStoryContainer = ({username}) => {
  return (
    <div className='chat-box-story-container'>
      <div className='chat-box-user-img'>
        <span>
          <ProfileIcon />
        </span>
      </div>
      <div className='chat-box-user-name'>{username}</div>
    </div>
  )
}

export default ChatBoxStoryContainer