
const BrowseMarkeplaceIcon = () => {
  return (
    <>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2954_13)">
          <path d="M4.6723 3.375C4.6723 4.07119 4.94886 4.73887 5.44115 5.23116C5.93343 5.72344 6.60111 6 7.2973 6C7.9935 6 8.66117 5.72344 9.15346 5.23116C9.64574 4.73887 9.9223 4.07119 9.9223 3.375C9.9223 2.67881 9.64574 2.01113 9.15346 1.51884C8.66117 1.02656 7.9935 0.75 7.2973 0.75C6.60111 0.75 5.93343 1.02656 5.44115 1.51884C4.94886 2.01113 4.6723 2.67881 4.6723 3.375Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.2973 16.5V21.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.9813 13.979C11.3091 13.5229 10.7363 12.9355 10.2973 12.252V21.752C10.2973 22.1498 10.1393 22.5314 9.85796 22.8127C9.57666 23.094 9.19513 23.252 8.7973 23.252C8.39948 23.252 8.01795 23.094 7.73664 22.8127C7.45534 22.5314 7.2973 22.1498 7.2973 21.752C7.2973 22.1498 7.13927 22.5314 6.85796 22.8127C6.57666 23.094 6.19513 23.252 5.7973 23.252C5.39948 23.252 5.01795 23.094 4.73664 22.8127C4.45534 22.5314 4.2973 22.1498 4.2973 21.752V15C4.2973 15.3978 4.13927 15.7794 3.85796 16.0607C3.57666 16.342 3.19513 16.5 2.7973 16.5C2.39948 16.5 2.01795 16.342 1.73664 16.0607C1.45534 15.7794 1.2973 15.3978 1.2973 15V12C1.2973 10.8065 1.77141 9.66193 2.61532 8.81802C3.45924 7.97411 4.60383 7.5 5.7973 7.5H8.4473C9.07975 7.50058 9.70497 7.63446 10.2822 7.89292C10.8594 8.15138 11.3757 8.5286 11.7973 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.2973 15V12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18.8093 1.13299C18.9451 1.01587 19.1185 0.951447 19.2978 0.951447C19.4771 0.951447 19.6505 1.01587 19.7863 1.13299C21.0932 2.31082 22.1287 3.75842 22.8212 5.3757C23.5137 6.99298 23.8468 8.74137 23.7973 10.5C23.7362 14.4716 23.0961 18.4131 21.8973 22.2C21.8014 22.5041 21.6111 22.7698 21.3539 22.9585C21.0968 23.1471 20.7862 23.2489 20.4673 23.249H18.1243C17.8054 23.2489 17.4948 23.1471 17.2377 22.9585C16.9805 22.7698 16.7902 22.5041 16.6943 22.2C15.4954 18.4131 14.8554 14.4716 14.7943 10.5C14.7455 8.74108 15.0792 6.99254 15.7724 5.37526C16.4656 3.75798 17.5018 2.31054 18.8093 1.13299Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.9253 13.372L22.8413 5.45599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.7983 18.874L23.7953 10.877" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_2954_13">
            <rect width="24" height="24" fill="white" transform="translate(0.547302)" />
          </clipPath>
        </defs>
      </svg>

    </>
  )
}

export default BrowseMarkeplaceIcon