import React from 'react'
import Header from '../header/Header'
import SideBar from '../sideBar/SideBar'
import { useLocation } from 'react-router-dom'
import SideBar1 from '../sideBar/SideBar1';

function Layout({ isLoggedIn }) {

  const { pathname } = useLocation();


  return (
    <>
      {
        (
          pathname === "/" || pathname === "/sellmysurfboard" || pathname === "/termsofuse" || pathname === "/dataprivacy" || pathname === "/imprint"
        ) && !isLoggedIn ? <header><Header /></header> : null
      }
      {isLoggedIn ? <SideBar1 /> : null}
    </>
  )
}

export default Layout