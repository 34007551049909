import React from 'react';
import './TermsOfUse.css';

const TermsOfUse = () => {
  return (
    <div className='terms-of-use-main-container'>
      <div className='t-o-u-m-c-inner'>

        <div className='heading-part'>
          <h1>Terms of Use - surfpoint.io</h1>
          <p>Our Terms of Use were posted on 27 April 2024 and last updated on 05 Mai 2024.</p>
          <p>Please read these terms and conditions carefully before using Our Service.</p>
        </div>

        <div className='content-part'>

          <div className='first-content'>
            <p>1. Introduction</p>
            <p>
              Welcome to Surfpoint.io, a dedicated marketplace for listing and searching for secondhand surfboards. These terms govern the use of our services and platform.
            </p>
          </div>

          <div className='second-content'>
            <p>2. Definitions and Interpretation</p>
            <p>In these Terms of Use, the following definitions apply:</p>
            <ul>
              <li>"Account" means the      account you must create to use our service.</li>
              <li>
                "User,"      "You," and "Your" refers to individuals or entities      using Surfpoint.io.
              </li>
              <li>
                "Listing" refers to      surfboards listed for sale by users on the platform.
              </li>
              <li>
                "Buyer" refers to a      user who purchases a surfboard listed on Surfpoint.io.
              </li>
              <li>
                "Seller" refers to a      user who lists a surfboard for sale on Surfpoint.io.
              </li>
            </ul>
          </div>

          <div className='third-content'>
            <p>3. Eligibility</p>
            <p>You must register for an account to use our services. By using Surfpoint.io, you represent that you have created an account on our platform.</p>
          </div>

          <div className='fourth-content'>
            <p>4. Account Registration and Responsibility</p>
            <p>
              Users must register an account to list or search for surfboards. You are responsible for maintaining the confidentiality of your account details and all activities under your account. Surfpoint.io is not liable for any loss or damages arising from your failure to maintain the security of your account.
            </p>
          </div>

          <div className='fifth-content'>
            <p>5. Listings and Transactions</p>
            <p>
              Users can list their surfboards for sale on Surfpoint.io. We do not handle or facilitate payment transactions; all agreements and transactions must be organized independently between the buyer and seller. We are not responsible for the verification of the content or the accuracy of listings.
            </p>
          </div>

          <div className='fifth-content'>
            <p>6. User Conduct</p>
            <p>
              Users must conduct themselves lawfully and responsibly. Prohibited activities include infringing on intellectual property, posting false information, and any form of harassment or illegal conduct.
            </p>
          </div>

          <div className='seventh-content'>
            <p>7. Fees and Payments</p>
            <p>
              Listing surfboards on Surfpoint.io is free until July 31, 2024. All transactions between the buyer and the seller must be organized independently by them, and Surfpoint.io is not responsible for these transactions.
            </p>
          </div>

          <div className='seventh-content'>
            <p>7. Fees and Payments</p>
            <p>
              Listing surfboards on Surfpoint.io is free until July 31, 2024. All transactions between the buyer and the seller must be organized independently by them, and Surfpoint.io is not responsible for these transactions.
            </p>
          </div>

          <div className='eighth-content'>
            <p>8. Content Ownership and Rights</p>
            <p>
              Users retain ownership of the content they post on Surfpoint.io. However, by posting, you grant Surfpoint.io a non-exclusive, worldwide, royalty-free license to use, reproduce, display, and distribute your content in connection with the service.
            </p>
          </div>

          <div className='ninth-content'>
            <p>9. Promotions and Special Offers</p>
            <p>
              Surfpoint.io may offer promotions, contests, or other special offers which are governed by separate terms and conditions and may be subject to additional rules specified at the time of the promotion.
            </p>
          </div>

          <div className='tenth-content'>
            <p>10. Disclaimers</p>
            <p>
              Surfpoint.io provides a platform for users to list and search for surfboards. We are not involved in the actual transaction between buyers and sellers and make no claims about the quality, safety, or legality of any surfboard listed.
            </p>
          </div>

          <div className='eleventh-content'>
            <p>11. Limitation of Liability</p>
            <p>
              Surfpoint.io shall not be liable for any damages arising from your use of our platform or services. Our liability to you in any circumstance is limited to the amount of fees you have paid to us.
            </p>
          </div>

          <div className='twelfth-content'>
            <p>12. Indemnification</p>
            <p>
              You agree to indemnify and hold harmless Surfpoint.io and its officers, directors, employees, and agents from any claims, damages, liabilities, and expenses arising out of your use of our services.
            </p>
          </div>

          <div className='thirteen-content'>
            <p>13. Modifications to Terms</p>
            <p>
              We reserve the right to modify these terms at any time. Changes will be communicated through your account's contact information and become effective immediately unless otherwise stated.
            </p>
          </div>

          <div className='fourteen-content'>
            <p>14. Governing Law and Jurisdiction</p>
            <p>
              These Terms of Use are governed by the laws of Switzerland, without regard to its conflict of law provisions.
            </p>
          </div>

          <div className='fifteen-content'>
            <p>15. Dispute Resolution</p>
            <p>
              Disputes arising under these terms will be resolved through final and binding arbitration under the rules of the Swiss Chambers' Arbitration Institution.
            </p>
          </div>

          <div className='sixteen-content'>
            <p>16. Severability and Waiver</p>
            <p>
              If any provision of these terms is found to be unenforceable or invalid, such provision will be minimally changed to reflect the parties' intention or eliminated to the minimum extent necessary so that these Terms of Use shall otherwise remain in full force and effect and enforceable.
            </p>
          </div>

          <div className='seventeen-content'>
            <p>17. Contact Information</p>
            <p>
              If you have any questions or concerns about these Terms of Use, please contact us at hello@surfpoint.io or https://surfpoint.io/contactus
            </p>
          </div>

        </div>

      </div>
    </div>
  )
}

export default TermsOfUse