import './ContactUs.css'
import { Header } from '../../template'
import { FaArrowRight } from "react-icons/fa6";
import { ContactUsForm } from '../../components';
import { pageNotFoundBanner } from '../../assets/images/404pageimg';

const ContactUs = () => {
  return (
    <div className='contact-us-main-container'>
      <Header />
      <div className='c-u-m-c-content-container'>
        <div className='c-u-m-c-c-c-inner'>

          <div className='cumccci-header-complement'>
            <div className='any-new-msg'>
              <span className='border'>Need any support?</span>
              <span className='arrow-icon'>
                We are happy to help you!
                <FaArrowRight />
              </span>
            </div>
          </div>

          <div className='cumccci-page-heading'>Contact us</div>

          <div className='cumccci-page-description'>
            Drop in on us with your questions, inquiries, feedback or shout-outs using the form below, and we'll get back to you as soon as possible.
          </div>

          <div className='cumccci-page-form'>
            <ContactUsForm />
          </div>

        </div>
      </div>
      <div className='cumc-banner-img'>
        <img src={pageNotFoundBanner} alt='banner-img'/>
      </div>
    </div>
  )
}

export default ContactUs