const Dashboard = () => {
  return (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_279_1402)">
          <circle cx="12" cy="12" r="10.75" stroke="currentColor" strokeLinecap="round" />
          <path d="M6.42099 18.0815C6.63168 16.9114 7.30402 15.8334 8.33576 15.0512C9.3687 14.268 10.6888 13.838 12.057 13.8503C13.4252 13.8625 14.7349 14.3162 15.7496 15.1172C16.763 15.9172 17.4109 17.0063 17.5955 18.1795" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
          <circle cx="12" cy="8.25" r="3.15" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </g>
        <defs>
          <clipPath id="clip0_279_1402">
            <rect width="24" height="24" fill="white" transform="translate(0 0.333008)" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default Dashboard
