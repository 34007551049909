import { HeroSectionBtns, HeroSectionReviews } from '../../../components';
import './HeroSection.css';
import { FaArrowRight } from "react-icons/fa6";
import iPhoneImg from '../../../assets/images/surfpoint-secondhand.png';
import bannerImg from '../../../assets/images/beforeregisterbanner.svg';


const HeroSection = () => {
  return (
    <div className="hero-section-component">

      <div className="h-s-text-section">

        <div className="any-new-msg">
          <span className='border'>New!</span>
          <span className='arrow-icon'>
          The #1 Surfboard-Marketplace
            <FaArrowRight />
          </span>
        </div>

        <div className="hero-section-heading">
          Catch Waves,<br />Not High Prices
        </div>

        <div className="hero-section-p1">
          Discover secondhand surfboards crafted for every rider's budget and style.
        </div>

        <div className="hero-section-p2">
          Our carefully curated marketplace offers you the best selection of secondhand surfboards, ensuring you never have to compromise on quality for price. Whether you're paddling out for the first time or carving up the coast like a pro, find the board that fits your budget and your passion. Let's make your surfing dreams a reality, without washing away your savings.
        </div>

        <div className='hero-section-btns'>
          <HeroSectionBtns />
        </div>

        <div className='hero-section-reviews'>
          <HeroSectionReviews />
        </div>

      </div>

      <div className="h-s-img-section">
        <div className='h-s-img-section-inner'>

          <div className='h-s-i-s-blob'></div>

          <div className='h-s-imgs'>

            <div className='iphone-img'>
              <img src={iPhoneImg} alt='iphone.png' />
            </div>

            <div className='a-man-banner-img'>
            <img src={bannerImg} alt='banner.svg' />
            </div>

          </div>

        </div>
      </div>

    </div>
  )
}

export default HeroSection