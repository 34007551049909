// Profile Veiwer with rating component 
import './ProfileViewerWithRating.css';
import StarRating from '../starRating/StarRating';
import { ProfileIcon } from '../../assets/icons';


const ProfileViewerWithRating = ({ rating = 3, user  }) => {

  return (
    <div className='d-flex p-v-container'>
      <div className='user-img-w-r '>
        <i>
          <ProfileIcon />
        </i>
      </div>
      <div className='user-name-w-r'>
        <p>@{user}</p>
        <StarRating rating={rating}/>
      </div>
    </div>
  )
}

export default ProfileViewerWithRating;