// components/Logout.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../auth/actions/authActions';
import {

  LogoutIcon,

} from '../../assets/icons/sidebar-icons';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    dispatch(logout());
    window.localStorage.clear();
    window.location.replace("/");   
  };
  
  return (
    <div className="sidebar-element sb-opt-font" onClick={handleLogout}>
      <LogoutIcon />
      <p>Logout</p>
    </div>

  );
};

export default Logout;
