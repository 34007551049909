import React from 'react';
import './Imprint.css';

const Imprint = () => {
  return (
    <div className='imprint-main-container'>
      <div className='i-m-c-inner'>
        <h1>Imprint</h1>

        <div>
          <h6>Responsible entity:</h6>
          <p>GEWE GmbH</p>
          <p>P.O. Box 583</p>
          <p>3000 Berne 22</p>
          <p>Switzerland</p>
          <div>
            <p>E-mail:hello@surfpoint.io</p>
          </div>
          <div>
            <h6>Authorised representatives</h6>
            <p>Andri Werren</p>
          </div>
          <div>
            <p>Name of the company: GEWE GmbH</p>
          </div>
          <div>
            <p>Registration number: CHE-440.568.800</p>
          </div>
          <div>
            <p>Value added tax identification number: CHE-440.568.800 VAT</p>
          </div>
        </div>

        <div>
          <h6>Disclaimer of liability</h6>
          <p>
            The author assumes no liability for the correctness, accuracy, up-to-dateness, reliability and completeness of the information.
          </p>
          <div>
            <p>
              Liability claims against the author for damages of a material or immaterial nature arising from access to or use or non-use of the published information, misuse of the connection or technical faults are excluded.
            </p>
          </div>
          <div>
            <p>
              All offers are subject to change. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without prior notice or to cease publication temporarily or permanently.
            </p>
          </div>
        </div>

        <div>
          <h6>Exclusion of liability for content and links</h6>
          <p>
            References and links to third-party websites lie outside our area of responsibility. Any responsibility for such websites is rejected. Access to and use of such websites is at the user's own risk.
          </p>
        </div>

        <div>
          <h6>Copyright declaration</h6>
          <p>
            The copyright and all other rights to content, images, photos or other files on this website belong exclusively to GEWE GmbH or the specifically named rights holders. The written consent of the copyright holder must be obtained in advance for the reproduction of any elements.
          </p>
          <div>
            <p>Source: BrainBox Solutions</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Imprint