// Seller Form Page 
import './SellerFormPage.css';
import {
  Checkbox,
  ChooseSizeRangeInput,
  // MapWithSearch,
  PrimaryColorBtn,
  ProfileViewerWithRating,
  showToastMessage
} from '../../components';
// import { TbCameraPlus } from "react-icons/tb";
// import { TfiHeart } from "react-icons/tfi";
// import { TiMessages } from "react-icons/ti";
import cameraSvg from '../../assets/images/sfp-camera-icon.svg';
import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import validateFields from '../../utils/validation';
import heic2any from 'heic2any';
//import defaultImg from './default-img.jpg';
import defaultImg from '../../assets/images/default-img.png';
import { GetLoggedInUser } from '../../utils/local-store';
import myData from './sellerdata.json';
import countryCodeData from './countryCode.json';
import ReactFlagsSelect from "react-flags-select";



const ClearIndicator = (props) => {
  const {
    children = '×',
    className = ' css-1xc3v61-indicatorContainer',
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.clearValue();
      }}
      style={getStyles('clearIndicator', props)}
    >
      {children}
    </div>
  );
};



const SellerFormPage = () => {
  const years = Array.from({ length: new Date().getFullYear() - 1999 }, (_, index) => new Date().getFullYear() - index);
  const vol_in_liters = myData.volumes;
  const width_values = myData.width;
  const thickness_values = myData.thickness;
  const level_of_surf_values = ['Beginner', 'Intermediate', 'Advanced'];
  const material_values = ['Polyurethane', 'Epoxy', 'Wood', 'Soft-top foam'];
  const fin_setup_values = ['Single Fin', 'Twin Fin', 'Thruster', 'Quad', '2 + 1', '3 + 2'];
  const [brands, setBrands] = useState({});
  const [locations, setLocations] = useState({});
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  let user = GetLoggedInUser();

  useEffect(() => {
    setUserName(user.name);

  }, [user]);

  const [product, setProduct] = useState({
    "name": "",
    "description": "",
    "price": "",
    "size": "5@0",
    "location": "",
    "brand_id": "",
    "seller_id": GetLoggedInUser().id,
    "thickness": 0,
    "volume": "",
    "width": 0,
    "year_of_pur": (new Date()).getFullYear(),
    "level_of_surf": "",
    "material": "",
    "fin_setup": "",
    "is_leash": "No",
    "is_surf_stock": "No",
    "is_fin": "No",
    "is_board_bag": "No",
    "email": "",
    "whatsapp": "",
    "facebook": ""

  });

  const [feet, setFeet] = useState(5);
  const [inches, setInches] = useState(0);
  const [sliderLabel, setSliderLabel] = useState(0);
  const [files, setFiles] = useState([]);
  const [updateMainImage, setUpdateMainImage] = useState(false);


  const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
  const [countryCode, setCountryCode] = useState("");

  const handleChangeLocations = (selectedOptions) => {
    setSelectedLocationOptions(selectedOptions);
    setProduct({ ...product, ['location']: selectedOptions })
  };



  const updateImageChange = (index) => {
    console.log(index);
    setUpdateMainImage(files[index]);
  };
  const handleFileChange = async (event) =>  {
    const selectedFiles = Array.from(event.target.files);
    let totalSize = 0;
    let isBreak = false;
    if (selectedFiles.length >= 1 && selectedFiles.length <= 5) {
      let index  = 0;
      for(const fileImage of selectedFiles) {
        var idxDot = fileImage.name.lastIndexOf(".") + 1;
        var extFile = fileImage.name.substr(idxDot, fileImage.name.length).toLowerCase();
        if (extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="heic"){
            //TO DO
            isBreak = false;
            // Convert HEIC to JPEG
          if(extFile=="heic") {
            try {
              const convertedFile = await heic2any({
                blob: fileImage,
                toType: 'image/jpeg',
              });
    
              selectedFiles[index] = (new File([convertedFile], fileImage.name.replace('.heic', '.jpeg'), {
                type: 'image/jpeg',
              }));
            } catch (error) {
              console.error('Error converting file:', error);
              break;
            } 
          }   
        }else{ 
           
            isBreak = true;
            break;
        }         
        totalSize = totalSize + fileImage.size;
        index++;
      }
      if(isBreak) {
        showToastMessage("Only jpg/jpeg, png and heic files are allowed!", "error");
        //alert("Only jpg/jpeg and png files are allowed!");
        return;
      }
      console.log(selectedFiles);
      if(isBreak === false && totalSize <= 5242880) {
        setFiles(selectedFiles);
        setUpdateMainImage(selectedFiles[0]);
      } else {
        showToastMessage('Please select total files size less than equal to 5 MB.', "error");
      }
    } else {
      showToastMessage('Please select between 1 and 5 files.', "error");
      //alert('Please select between 1 and 5 files.');
    }
  };

  const sizeHandleChange = (event) => {
    const value = parseInt(event.target.value);
    let tempFeet = Math.floor(value / 12);
    let tempInch = value % 12;
    setFeet(tempFeet);
    setInches(tempInch);
    let label = tempFeet + "'" + tempInch;
    setSliderLabel(label);
    // if(value % 12  <=9) {
    //   setProduct({ ...product, 'size': feet + '.0' + inches });
    // } else {

    // }

    setProduct({ ...product, 'size': label });
    console.log(product);
  };



  useEffect(() => {
    const fetchBrands = async () => {
      const response = await api.get("/brands/");
      setBrands(response.data);
    };
    const fetchLocations = async () => {
      const locationsResponse = await api.get("/locations/");
      if (locationsResponse.data.length > 0) {
        let options = await locationsResponse.data.map((x) => { return { value: x.id, label: x.countries == null ? x.city_name : x.city_name + ", " + x.countries.country_name } });
        setLocations(options);

      }

    };
    fetchBrands();
    fetchLocations();
  }, []);


  const [material, setMaterial] = useState();
  const [materialsRow, setMaterialsRow] = useState();

  const labelStyle = {
    position: 'absolute',
    left: (sliderLabel) + 'px'
  }

  const handleProductChange = (event, keyName) => {

    let val = event.target.value;
    if (keyName.indexOf('is_fin', 'is_leash', 'is_surf_stock', 'is_board_bag') > -1) {
      val = event.target.checked ? "Yes" : "No";
    }




    setProduct({ ...product, [keyName]: val });

  };

  const validateImages = () => {
    if (files.length > 0) {
      return true;
    } else {
      showToastMessage(`Select image between 1 to 5`, 'error');
      return false;
    }
  }

  const convertData = async (prData) => {
    product.size = prData.size.replace("'", ".");
    if (prData.width.indexOf("_") > 0) {
      let tempArr = prData.width.split("_");
      prData.width = parseInt(tempArr[0]) + parseInt(tempArr[1]) / parseInt(tempArr[2]);
    }
    if (prData.thickness.indexOf("_") > 0) {
      let tempArr = prData.thickness.split("_");
      prData.thickness = parseInt(tempArr[0]) + eval(tempArr[1])
    }
    if (countryCode.length > 0) {

      prData.whatsapp = countryCodeData[countryCode] + prData.whatsapp;
    }
    return prData;
  };

  const validateSocialField = () => {
    let res = true;
    if (product.email == "" && product.facebook == "" && product.whatsapp == "") {
      res = false;
    }
    return res;
  }

  const submitProductHandle = async () => {
    try {




      const fields = [
        { value: product.name, validationType: 'required', keyName: 'Board Name' },
        { value: product.description, validationType: 'required', keyName: 'Description' },
        { value: product.location, validationType: 'required', keyName: 'Location' },
        { value: product.brand_id, validationType: 'required', keyName: 'Select Brand' },
        { value: product.price, validationType: 'required', keyName: 'Price' },
        { value: product.volume, validationType: 'required', keyName: 'Volume' },
        { value: product.width, validationType: 'required', keyName: 'Width' },
        { value: product.thickness, validationType: 'required', keyName: 'Thickness' },
        { value: product.fin_setup, validationType: 'required', keyName: 'Fin Setup' },
        { value: product.material, validationType: 'required', keyName: 'Material' },
        { value: product.level_of_surf, validationType: 'required', keyName: 'Level of surface' },
      ];

      if (validateFields(fields) && validateImages() && validateSocialField()) {

        let params = await convertData(product)
        await api.post('/products', params).then(async (resp) => {
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          const form = new FormData();
          for (const f of files) {
            form.append("uploadedImages", f);
          }
          await api.post('/product-image/' + resp.data.id, form, config
          ).then((res) => { console.log(res); navigate('/successful'); }).catch((err) => {
            alert('file upload error');
            console.log(err);
          });

        });

        //dispatch(loginSuccess({ user, accessToken, refreshToken, expiryTime }));
        // Redirect to another page
        //; navigate('/successful'); // Replace '/dashboard' with the desired page path
        // navigate('/sellerProfile');
      }
    } catch (error) {
      console.log(error);
      let errorMessage = error.response.data.message || error.message;
      showToastMessage(errorMessage, "error");
      // dispatch(loginFailure());
    }
  };

  const updateLocation = (locationVal) => {
    console.log(locationVal)
    let keyName = 'location';
    setProduct({ ...product, [keyName]: locationVal })
  }



  return (
    <>

      <div className='main-img mobile-main-img'>
        {
          <img
            src={updateMainImage ? URL.createObjectURL(updateMainImage) : defaultImg}
            id="product-image"
            alt='product-main-img'
          />
        }
        <div className='add-img-btn'>
          <label htmlFor="file-upload">
            <img src={cameraSvg} alt='camera-icon.svg' />
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>
      </div>

      <div className='form-main-container'>
        <div className='img-section'>
          <div className='set-imgs'>
            <div className='main-img'>
              {
                <img
                  src={updateMainImage ? URL.createObjectURL(updateMainImage) : defaultImg}
                  id="product-image"
                  alt='product-main-img'
                />
              }
              <div className='add-img-btn'>
                <label htmlFor="file-upload">
                  <img src={cameraSvg} alt='camera-icon.svg' />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className='other-imgs'>
              {
                files.map((file, index) => (
                  <div className='img-1' key={index} >
                    <img key={index}
                      src={URL.createObjectURL(file)}
                      alt={`selected-img`}
                      onClick={e => updateImageChange(index)}
                    />
                  </div>
                ))
              }

            </div>

            {/* <div className='img-1'>
              {/* <img src='#' alt='#' /> /}
            </div>
            <div className='img-1'>
              {/* <img src='#' alt='#' /> /}
            </div>
            <div className='img-1'>
              {/* <img src='#' alt='#' /> /}
            </div> */}

          </div>


          <div className='item-description-section'>
            <h6>Surfboard description</h6>
            <textarea
              placeholder='Text input...'
              rows={4}
              value={product.description}
              onChange={(event) => handleProductChange(event, 'description')}
            />
          </div>

          <div className='contact-info-section'>
            <div>
              <p>Contact-Information (One is required)</p>
              <input type='email' placeholder='E-mail' value={product.email}
                onChange={(event) => handleProductChange(event, 'email')} />
              <input type='text' placeholder='Facebook-Profile' value={product.facebook}
                onChange={(event) => handleProductChange(event, 'facebook')} />
              <div>
                <ReactFlagsSelect selected={countryCode} onSelect={code => setCountryCode(code)} selectedSize={12} optionsSize={12} 
                  searchable={true}/>
                <input type='text' placeholder='WhatsApp-Number' value={product.whatsapp}
                  onChange={(event) => handleProductChange(event, 'whatsapp')} />
              </div>
            </div>
          </div>

          <div className='list-my-surfboard-btn'>
            <PrimaryColorBtn
              padding={'15px'}
              width={"362px"}
              text={"List my surfboard now!"}
              onClick={(event) => submitProductHandle()}
            />

            {/* <p>
              List your surfboard for free until the end of July 2024. Starting August 1, 2024, a listing fee of 3% of the selling price will apply to each surfboard listing
            </p> */}
          </div>

        </div>

        <div className='data-section'>

          <div className='d-flex' style={{ justifyContent: "space-between" }}>
            <ProfileViewerWithRating user={userName} rating={3} />
            <div className='d-flex gap-5px' >
              {/* <div className='h-m-icons'>
                <i><TfiHeart /></i>
              </div> */}
              {/* <div className='h-m-icons'>
                <i><TiMessages /></i>
              </div> */}
            </div>
          </div>
        

          {/* <div className='form-choose-signal'>
            <div className='choose-signal'>
              <i></i><span>Choose</span>
            </div>
          </div> */}
          <div className='seller-form-page-location-map'>
            {/* <div className='input-type-text'>
                <input
                  type='text'
                  placeholder='Location'
                  value={product.location}
                  onChange={(event) => handleProductChange(event, 'location')}
                />
              </div> */}
            { /* <ChooseLocation /> */}
            {/* <MapWithSearch updateLocation={locationVal => updateLocation(locationVal)} /> */}
            <div className='item-description-section mobile'>
            <h6>Surfboard description</h6>
            <textarea
              placeholder='Text input...'
              rows={4}
              value={product.description}
              onChange={(event) => handleProductChange(event, 'description')}
            />
          </div>
            <div className='contact-info-section mobile'>
            <div>
              <p>Contact-Information (One is required)</p>
              <input type='email' placeholder='E-mail' value={product.email}
                onChange={(event) => handleProductChange(event, 'email')} />
              <input type='text' placeholder='Facebook-Profile' value={product.facebook}
                onChange={(event) => handleProductChange(event, 'facebook')} />
              <div>
                <ReactFlagsSelect selected={countryCode} onSelect={code => setCountryCode(code)} selectedSize={12} optionsSize={12} 
                  searchable={true}/>
                <input type='text' placeholder='WhatsApp-Number' value={product.whatsapp}
                  onChange={(event) => handleProductChange(event, 'whatsapp')} />
              </div>
            </div>
          </div>
            <Select
              placeholder="Choose your location"
              value={selectedLocationOptions}
              onChange={handleChangeLocations}
              isClearable
              options={locations}
              components={{ ClearIndicator }}

            />
          </div>

          <div className='form-inputs'>
            <div className='input-type-text'>
              <input
                type='text'
                placeholder='Board-Name'
                value={product.name}
                onChange={(event) => handleProductChange(event, 'name')}
              />
              <div className='sfp-price-input'>
                <span>$</span>
                <input
                  type='text'
                  placeholder='Price in USD'
                  value={product.price}
                  onChange={(event) => handleProductChange(event, 'price')}
                />
              </div>

            </div>

            <div className='choose-size-slider'>
              <h6>Choose Size</h6>
              <div className='c-s-s-tumb-lable'>
                <label style={labelStyle}>{feet + "'" + inches}</label>
              </div>
              <ChooseSizeRangeInput
                min={60}
                max={120}
                feet={feet}
                inches={inches}
                handleChange={sizeHandleChange}
              />

            </div>




            <div className='select-boxes' >
              <select
                value={product.brand_id}
                onChange={(event) => handleProductChange(event, 'brand_id')}
              >
                <option value={0}>Brand</option>
                {
                  brands.length > 0 && brands.map((item, index) => {
                    return (
                      <option
                        key={item.id}
                        value={item.id}
                        defaultValue={product.brand_id === item.id ? 'selected' : ''}
                      >
                        {item.brand_name}
                      </option>
                    )
                  })
                }
              </select>

              <select
                className='select-boxes'
                value={product.volume}
                onChange={(event) => handleProductChange(event, 'volume')}
              >
                <option value={0}>Volume</option>
                {
                  vol_in_liters.map((item, index) => {
                    return (
                      <option
                        value={item}
                        key={item}
                        defaultValue={product.volume === item ? 'selected' : ''}>
                        {item} Liters
                      </option>)
                  })
                }
              </select>

              <select
                className='select-boxes'
                value={product.width}
                onChange={(event) => handleProductChange(event, 'width')}
              >
                <option value={0}>Width</option>
                {
                  width_values.map((item, index) => {
                    return (
                      <option
                        value={item.replace('/', '_')}
                        key={item}
                        defaultValue={product.width}
                      >
                        {item.replace('_', ' ')}
                      </option>
                    )
                  })
                }
              </select>

              <select
                className='select-boxes'
                value={product.thickness}
                onChange={(event) => handleProductChange(event, 'thickness')}
              >
                <option value={0}>Thickness</option>
                {
                  thickness_values.map((item, index) => {
                    return (
                      <option
                        value={item}
                        key={item}
                        defaultValue={product.thickness}
                      >
                        {item.replace('_', ' ')}
                      </option>
                    )
                  })
                }
              </select>

              <select
                value={product.fin_setup}
                onChange={(event) => handleProductChange(event, 'fin_setup')}
              >
                <option value={0}>Fin Setup</option>
                {
                  fin_setup_values.map((item, index) => {
                    return (
                      <option
                        value={item}
                        key={item}
                        defaultValue={product.fin_setup}
                      >
                        {item}
                      </option>
                    )
                  })
                }
              </select>

              <select
                value={product.year_of_pur}
                onChange={(event) => handleProductChange(event, 'year_of_pur')}
              >
                <option value={0}>Year of Purchase</option>
                {
                  years.map((element, index) => {
                    return (
                      <option
                        key={element}
                        value={element}
                        defaultValue={product.year_of_pur}
                      >
                        {element}
                      </option>
                    )
                  })
                }
              </select>

            </div>


            <div className='choose-material-section'>
              <h6>Material</h6>
              <div className='materials-row'>
                {
                  material_values.map((item, index) => {
                    return (
                      <div key={index} className={material === item ? 'active' : ''}>
                        <label>
                          <input
                            type='radio'
                            onClick={(event) => {
                              setMaterial(item);
                              handleProductChange(event, 'material')
                            }}
                            name='materil'
                            value={item}
                          />
                          {item}
                        </label>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className='choose-material-section'>
              <h6>Level of Surf</h6>
              <div className='materials-row'>
                {
                  level_of_surf_values.map((item, index) => {
                    return (
                      <div key={index} className={materialsRow === item ? 'active' : ''}>
                        <label>
                          <input
                            type='radio'
                            onClick={(event) => {
                              setMaterialsRow(item);
                              handleProductChange(event, 'level_of_surf');
                            }}
                            name='materials-row'
                            value={item}
                          />
                          {item}
                        </label>
                      </div>
                    )
                  })
                }
              </div>
            </div>


            <div className='whats-included-checkboxes'>
              <h6>Whats Included?</h6>
              <div className='checkboxes'>
                <Checkbox
                  name="Fin"
                  value="Yes"
                  checked={product.is_fin === "Yes" ? 'checked' : ''}
                  onClick={(event) => handleProductChange(event, 'is_fin')}
                />
                <Checkbox
                  name="Leash"
                  value="Yes"
                  checked={product.is_leash === "Yes" ? 'checked' : ''}
                  onClick={(event) => handleProductChange(event, 'is_leash')}
                />
                <Checkbox
                  name="Surf-Sock"
                  value="Yes"
                  checked={product.is_surf_stock === "Yes" ? 'checked' : ''}
                  onClick={(event) => handleProductChange(event, 'is_surf_stock')}
                />
                <Checkbox
                  name="Board-Bag"
                  value="Yes"
                  checked={product.is_board_bag === "Yes" ? 'checked' : ''}
                  onClick={(event) => handleProductChange(event, 'is_board_bag')}
                />
              </div>
            </div>

          </div>

          <div className='list-my-surfboard-btn lmsb-mobile'>
            <PrimaryColorBtn
              padding={'15px'}
              width={"100%"}
              text={"List my surfboard now!"}
              onClick={(event) => submitProductHandle()}
            />
          </div>

        </div>
      </div>
    </>
  )
}


export default SellerFormPage