const ProfileIcon = () => {
  return (
    <>
      <svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_292_963)">
          <path d="M14.7285 23.2183C8.45376 23.2183 3.35693 18.1417 3.35693 11.8919C3.35693 5.64212 8.45376 0.588135 14.7285 0.588135C21.0033 0.588135 26.1001 5.66469 26.1001 11.9145C26.1001 18.1643 21.0033 23.2408 14.7285 23.2408V23.2183ZM14.7285 1.87419C9.17864 1.87419 4.64813 6.36412 4.64813 11.9145C4.64813 17.4648 9.17864 21.9548 14.7285 21.9548C20.2784 21.9548 24.8089 17.4423 24.8089 11.9145C24.8089 6.38668 20.2784 1.87419 14.7285 1.87419Z" fill="currentColor" />
          <path d="M28.9543 46.7058H1.04638C0.68394 46.7058 0.412109 46.4125 0.412109 46.0741V36.4851C0.412109 28.4754 6.95869 21.9548 15.0003 21.9548C23.042 21.9548 29.5886 28.4754 29.5886 36.4851V46.0741C29.5886 46.4351 29.2941 46.7058 28.9543 46.7058ZM1.7033 45.4423H28.32V36.4851C28.32 29.1748 22.3624 23.2409 15.023 23.2409C7.68357 23.2409 1.7033 29.1748 1.7033 36.4851V45.4423Z" fill="currentColor" />
        </g>
        <defs>
          <clipPath id="clip0_292_963">
            <rect x="0.412109" y="0.588135" width="29.1765" height="41.4118" rx="9" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default ProfileIcon;
