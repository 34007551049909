// Search Icon Component
import { useState } from 'react';
import './SearchIcon.css';
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const SearchIcon = () => {

  const [srcValue, setSrcValue] = useState('');
  const [expend, setExpend] = useState(false);
  const navigate = useNavigate();

  const searchExpend = () => {
    if (expend) {
      //setSrcValue('');
      navigate('/products/?q=' + srcValue);
      document.getElementById('myTextField').focus();
    } else {
      setExpend(true);
      document.getElementById('myTextField').focus();
    }
  }

  const myFunction = () => {
    if (expend && srcValue === '') {

      setExpend(false);
      navigate('/products');
    }
  }

  return (
    <div className='src-icon-box'>
      <div className={expend ? 'expend-search-btn active' : 'expend-search-btn deactive'}
        onBlur={() => myFunction()}
      >
        <input
          type='text'
          id='myTextField'
          className="search-box"
          placeholder='Search'
          value={srcValue}
          onChange={(e) => {setSrcValue(e.target.value);}}
        // onBlur={() => myFunction()}
        />

        <button className='search-btn' onClick={() => searchExpend()}>
          <i className="icon-i-tag">
            <IoSearchOutline />
          </i>
        </button>
      </div>
    </div>
  )
}

export default SearchIcon