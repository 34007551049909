import React from 'react';
import "./FooterCompo.css";
import { Link } from 'react-router-dom';
import Logo from '../logo/Logo';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';

const FooterCompo = () => {
  return (
    <div className='footer-compo-main-container'>
      <div>
        <div className='logo'>
          {/* <img src={Logo} alt='logo' /> */}
          <Logo />
        </div>
        <div>
          <Link className='navlink' href={'/#hppc'}>Surfboard Marketplace</Link>
          <Link className='navlink' to={'/sellmysurfboard'}>Sell my Surfboard</Link>
          <Link className='navlink' to={'/contactus'}>Contact us</Link>
          <Link className='navlink' to={'/login'}>Login</Link>
          <Link className='navlink' to={'/signup'}>Register</Link>
        </div>
        <div>
          <Link target='blank' to={"https://www.facebook.com/surfpointio"}>
            <FaFacebook />
          </Link>
          <Link 
            target='blank' 
            to={"https://www.instagram.com/surfpoint.io?igsh=MTlxbW8xYWx2YnFpNA%3D%3D&utm_source=qr"}
          >
            <FaInstagram />
          </Link>
          <Link target='blank' to={"https://www.youtube.com/@surfpointio"}><FaYoutube /></Link>
        </div>
      </div>
      <hr/>
      <div>
        <p>© 2024 surfpoint.io. All rights reserved.</p>
        <Link className='navlink' target='blank' to={'/privacy-policy'}>Privacy Policy</Link>
        <Link className='navlink' target='blank' to={'/terms-and-conditions'}>Terms of Service</Link>
        <Link className='navlink' target='blank' to={'/imprint'}>Imprint</Link>
      </div>
    </div>
  )
}

export default FooterCompo