import { PrimaryColorBtn } from '../../components';
import './SuccessPage.css';
import SuccessPageBanner from '../../assets/images/successPageBanner.svg';
import { Link } from 'react-router-dom';

const SuccessPage = () => {
  return (
    <div className='success-page-container'>
      <div className='s-p-c-inner'>

        <div className='image-container'>
          <p className='mobile-success-text'>
            Your Surfboard is <br /> Successfully Listed
          </p>
          <div className='banner-img'>
            <img src={SuccessPageBanner} alt='banner.svg' />
          </div>
        </div>

        <div className='s-p-c-i-content-container'>
          <div className='s-p-c-i-c-c-inner'>
            <p className='success-text'>
              Your Surfboard is <br /> Successfully Listed
            </p>
            <Link to="/products"><PrimaryColorBtn text={'YAY!'} /></Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SuccessPage;