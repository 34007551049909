import './ChooseSizeRangeInput.css';

const ChooseSizeRangeInput = ({min, max, feet, inches, handleChange}) => {
  return (
    <div className='range-input-box '>
       <input type="range" min={min} max={max} value={feet * 12 + inches} onChange={handleChange} />
    </div>
  )
}

export default ChooseSizeRangeInput