// Product Landing Page 
import './Product.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductDataSection from './ProductDataSection';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io"; import api from '../../utils/api';
import { imageUrl } from '../../utils/local-store';
import { GoBackBtn } from '../../components';

const Product = () => {

  const { id } = useParams();
  const [productData, setProductData] = useState([]);



  useEffect(() => {
    const fetchProducts = async () => {
      const response = await api.get(`/products/${id}`);
      setProductData(response.data);
      if (response.data.ProductImages.length > 0) {
        setMainImg(response.data.ProductImages[0].image_name)
      }
    };
    fetchProducts(id);
  }, [id]);

  const [mainImg, setMainImg] = useState();

  const changeMainImg = (imgName) => {
    setMainImg(imgName);
  }

  return (
    <>
      <GoBackBtn />
      {

        productData && productData?.ProductImages?.length > 0 ?
          <div className='main-img main-img-mobile'>
            <img
              src={imageUrl + mainImg}
              alt={productData.ProductImages[0].image_name}
            />
          </div>
          :
          <div className='main-img main-img-mobile'></div>
      }

      <div className='content-body product-content-body product-page-on-mobile' >
        <div className='product-content'>

          {/* Image Section  */}
          <div className='img-section'>
            <div className='set-imgs'>
              {
                productData && productData?.ProductImages?.length > 0 ?
                  <div className='main-img product-pg-main-img'>
                    <img
                      src={imageUrl + mainImg}
                      alt={productData.ProductImages[0].image_name}
                    />
                  </div>
                  :
                  <div className='main-img'></div>
              }

              <div className='pp-other-imgs-upper-div product-pg-other-imgs' >
                <div className='pp-other-imgs'>
                  <div className='other-imgs-inner'>
                    {
                      productData && productData.ProductImages ?
                        productData.ProductImages.map((item, index) => {
                          return (
                            <div className='single-img' key={index}>
                              <img
                                src={imageUrl + item.image_name}
                                alt={item.image_name}
                                onClick={() => changeMainImg(item.image_name)}
                              />
                            </div>
                          )
                        })

                        :
                        <>
                          <div className='single-img' ></div>
                          <div className='single-img' ></div>
                          <div className='single-img' ></div>
                        </>
                    }
                  </div>
                </div>
                <div className='pre-next-arrow-icons'>
                  <span className='pre-next-arrow-icon'><IoIosArrowBack /></span>
                  <span className='pre-next-arrow-icon'><IoIosArrowForward /></span>
                </div>
              </div>

            </div>
          </div>


          {<ProductDataSection productData={productData} changeMainImg={changeMainImg} />}

        </div>
      </div>
    </>
  )
}

export default Product;