const Chats = () => {
  return (
    <>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2206_10)">
          <path fillRule="evenodd" clipRule="evenodd" d="M3.52637 6.29159C3.52637 3.4151 5.95538 1.08325 8.95172 1.08325H13.0207C16.0171 1.08325 18.4461 3.4151 18.4461 6.29158V7.4559C20.7261 7.97625 22.5151 10.0875 22.5151 12.5416V16.0584C22.5151 16.6676 22.7006 17.2636 23.0488 17.7729L24.7786 20.3034C24.9974 20.6234 25.0157 21.0329 24.8262 21.3698C24.6368 21.7066 24.2702 21.9166 23.8714 21.9166H13.0207C10.0244 21.9166 7.59538 19.5847 7.59538 16.7083V15.6666H2.17003C1.77127 15.6666 1.40464 15.4566 1.21521 15.1198C1.02579 14.7829 1.04408 14.3734 1.26286 14.0534L2.99267 11.5229C3.34086 11.0136 3.52637 10.4176 3.52637 9.80839V6.29159ZM9.76552 15.6666V16.7083C9.76552 18.4341 11.2229 19.8333 13.0207 19.8333H21.8615L21.2344 18.916C20.6541 18.067 20.3449 17.0738 20.3449 16.0584V12.5416C20.3449 11.2114 19.4969 10.1154 18.4461 9.65303V10.4583C18.4461 13.3347 16.0171 15.6666 13.0207 15.6666H9.76552ZM4.17996 13.5833L4.80701 12.666C5.38733 11.817 5.69651 10.8238 5.69651 9.80839V6.29159C5.69651 4.5657 7.15391 3.16659 8.95172 3.16659H13.0207C14.8185 3.16659 16.2759 4.5657 16.2759 6.29158V10.4583C16.2759 12.1841 14.8185 13.5833 13.0207 13.5833H4.17996Z" fill="currentColor" />
        </g>
        <defs>
          <clipPath id="clip0_2206_10">
            <rect width="25" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default Chats