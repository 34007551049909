import React from 'react';
import './SellMySurfboard.css';
import HeroSection from './heroSection/HeroSection';

const SellMySurfboard = () => {
  return (
    <div className='sell-my-surf-main-container'>
      <div className='s-m-s-m-c-inner'>

        <div className="hero-section">
          <HeroSection />
        </div>

      </div>
    </div>
  )
}

export default SellMySurfboard