import React, { useState } from 'react';
import './SignUpPage.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../../auth/actions/authActions'
import api from '../../utils/api';
import validateFields from '../../utils/validation';
import bannerImg from '../../assets/images/beforeregisterbanner.svg';
import { Logo, showToastMessage } from '../../components';

const SignUpWithEmail = () => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const [isAgree, setIsAgree] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkHandler = () => {
    setIsAgree(!isAgree)
  }


  const handleLogin = async () => {
    try {
      const fields = [
        { value: firstName, validationType: 'required', keyName: 'First Name' },
        { value: lastName, validationType: 'required', keyName: 'Last Name' },
        { value: userName, validationType: 'required', keyName: 'Email' },
        { value: userName, validationType: 'email', keyName: 'Email' },
        { value: password, validationType: 'required', keyName: 'Password' },
        { value: isAgree, validationType: 'checkbox', keyName: 'I agree checkbox' }
        // { value: 'john@example.com', validationType: 'email' }
      ];
      //   console.log(validateFields(fields)); // Output: true
      if (validateFields(fields)) {
        const response = await api.post('/auth/signup', { firstName, lastName, userName, password });
        const { user, accessToken, refreshToken, expiryTime } = response.data;
        dispatch(loginSuccess({ user, accessToken, refreshToken, expiryTime }));
        // Redirect to another page
        showToastMessage("You successfully registered and login", "success!");
        navigate('/dashboard'); // Replace '/dashboard' with the desired page path
      }
    } catch (error) {
      console.log(error);
      let errorMessage = error.response.data.message || error.message;
      showToastMessage(errorMessage, "error");
      dispatch(loginFailure());
    }
  };


  return (
    <>
      <div className='login-page-logo'><Logo /></div>
      <div className='container'>
        <div className='grid grid-two-column'>
          <div className='image-container'>
            <div className='banner-img'>
              <img src={bannerImg} alt='banner.png' />
            </div>
          </div>

          <div className='form-container'>
            <div className='inner-form-container'>
              <h2>Signup with E-Mail</h2>
              {/* <p>Lorem ip Lrdem ips um <br /> dolor sit amet.</p> */}

              <form className="grid sign-up-form">
                <input
                  type='text'
                  name='firstName'
                  placeholder='First Name'
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
                <input
                  type='text'
                  name='lastName'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
                <input
                  type='text'
                  name='userName'
                  placeholder='Email'
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                />
                <input
                  type='password'
                  name='password'
                  placeholder='Password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <label className='label-of-checkbox'>
                  {/* <Checkbox /> */}
                  <input type='checkbox' checked={isAgree} onClick={e => checkHandler()} />
                  <div className="signup-page-links">
                    I agree to the SurfPoint
                    <NavLink to={'/terms-and-conditions'} target="_blank"> Terms of <br /> Service </NavLink> and
                    <NavLink to={"/privacy-policy"} target="_blank"> Privacy Policy</NavLink>
                  </div>
                </label>
                <button type='button' className='sign-up-btn' onClick={e => handleLogin()}>
                  Sign up
                </button>
                <NavLink to={"/login"} className="signup-page-links">
                  Login
                </NavLink>
              </form>
            </div>
          </div>
        </div>
      </div>


      {/* Mobile  */}
      <div className='mobile-container'>
        <div className='m-c-inner'>
          <div className='m-c-i-heading-container'>
            <h2>Signup with E-Mail</h2>
            {/* <p>Lorem ip Lrdem ips um <br /> dolor sit amet.</p> */}
          </div>

          <div className='m-c-i-image-container'>
            <div className='banner-img'>
              <img src={bannerImg} alt='banner.png' />
            </div>
          </div>
          <div className='m-c-i-form-container'>
            <form className="grid sign-up-form">
              <input
                type='text'
                name='firstName'
                placeholder='First Name'
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
              <input
                type='text'
                name='lastName'
                placeholder='Last Name'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
              <input
                type='text'
                name='userName'
                placeholder='Email'
                value={userName}
                onChange={e => setUserName(e.target.value)}
              />
              <input
                type='password'
                name='password'
                placeholder='Password'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <label className='label-of-checkbox'>
                {/* <Checkbox /> */}
                <input type='checkbox' checked={isAgree} onClick={e => checkHandler()} />
                <div className="signup-page-links">
                  I agree to the SurfPoint
                  <NavLink to={'/terms-and-conditions'} target="_blank"> Termns of <br /> Service </NavLink> and
                  <NavLink to={"/privacy-policy"} target="_blank"> Privacy Policy</NavLink>
                </div>
              </label>
              <button type='button' className='sign-up-btn' onClick={e => handleLogin()}>Sign up</button>
              <NavLink to={"/login"} className="navlink login-page-link">
                Login
              </NavLink>
            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default SignUpWithEmail