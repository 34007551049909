import React from "react";

const ColorfulGoogleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="red"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="google-icon"
    >
      <path d="M19 19H5V5h14v14z" />
      <path d="M10 10L10 14L14 14z" />
      <path d="M10 10L14 10L14 6z" />
    </svg>
  );
};

export default ColorfulGoogleIcon;