import './Header.css';
import { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  SearchIcon,
  ProfileViewer,
  Logo,
  HeaderLogRegBtns,
  ProfileIcon,
  MenuIcon,
  Logout

} from '../../components';
import { IsUserLoggedIn } from '../../utils/local-store';
import { FiMenu } from "react-icons/fi";
import { HiOutlineXMark } from "react-icons/hi2";
import { useLocation } from 'react-router-dom'
import { useState } from 'react';
import { AccountSettingsIcon } from '../../assets/icons/sidebar-icons';

const Header = () => {

  const isLoggedIn = IsUserLoggedIn();
  const { pathname } = useLocation()
  const [stickyHeader, setStickyHeader] = useState(false);



  window.addEventListener('scroll', () => {
    const scroll = window.scrollY || document.body.scrollTop;
    scroll > 38 ? setStickyHeader(true) : setStickyHeader(false);
  });

  // useEffect(()=> {
  //   function handleClickOutside() {
  //     document.addEventListener('click', (e) => {
  //       //console.log(e);
  //     });
  //   }
  //   //handleClickOutside();
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };

  // }, [])



  const [menuOpt, setMenuOpt] = useState(false);
  const [menuOptLogIn, setMenuOptLogIn] = useState(false);

  //const boxRef = useRef(null);
  //const outSideClickOfBox = OutSideClick(boxRef, setMenuOptLogIn)

  const afterLoginMenu = (e) => {
    setMenuOptLogIn(true);
  }

  const location = useLocation()

  useEffect(() => {
    // Scroll to the element with the ID from the fragment identifier
    if (location.hash) {
      const element = document.querySelector(location.hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location.hash])

  return (
    <>
      {
        isLoggedIn === true  || pathname.indexOf('/product/') >= 0? 
          <header className='after-login'>
            <div className='content-header'>
              <div className='c-h-left-side'>
                <div>
                  <div  onClick={(e) => setMenuOptLogIn(true)}><MenuIcon   /></div>
                  <div
                    className='mobile-sidebar-container menu-options'
                    style={{ display: menuOptLogIn ? "block" : "none" }}
                  >
                    <div className='header-navlinks' >
                      <button className='menuCloseBtn' onClick={(e) => {console.log(menuOptLogIn); setMenuOptLogIn(false)}}>
                        <HiOutlineXMark />
                      </button>
                      <NavLink to={'/'}  className={(e) => e.isActive ? "active" : ""} onClick={(e) => {console.log(menuOptLogIn); setMenuOptLogIn(false)}}>Home</NavLink>
                     
                      <NavLink
                        to={'/sellerform'}
                        className={(e) => e.isActive ? "active" : ""}
                        onClick={(e) => {console.log(menuOptLogIn); setMenuOptLogIn(false)}}
                      >
                        Sell Surfboard
                      </NavLink>
                      <NavLink
                        to={'/products'}
                        className={(e) => e.isActive ? "active" : ""}
                        onClick={(e) => {console.log(menuOptLogIn); setMenuOptLogIn(false)}}
                      >Browse Marketplace </NavLink>
                     
                    </div>

                    <div className='header-navlinks footer-navlinks'>
                      <NavLink to={'/settings'} className="navlink">
                        <div className="sidebar-element sb-opt-font">
                          <AccountSettingsIcon />
                          <p>Account Settings</p>
                        </div>
                      </NavLink>
                      <Logout />
                    </div>

                  </div>
                </div>
                {pathname.indexOf('/products') >= 0 ? <SearchIcon /> : ''}
              </div>
              <div>
                <div className='profile-viewer'>
                  <NavLink to={'/settings'} >
                    <ProfileViewer />
                  </NavLink>
                  {/* <Logout /> */}
                </div>
                <div className='profile-viewer-icon'>
                  <ProfileIcon />
                </div>
              </div>
            </div>
          </header>
          :
          <div className='befor-login'>

            <div className='header-upper-div'>
              <div>
                BETA Version – Got feedback?
                <NavLink
                  to='https://insigh.to/b/surfpointio'
                  target='_blank'
                  className='navlink'                  
                >
                  Click here
                </NavLink>
              </div>
            </div>

            <header className={stickyHeader ? 'bl-header sticky-header' : 'bl-header'}>

              <div className='left-side-content'>
                <div className='logo'>
                  <Logo />
                </div>
                <div className='header-navlinks'>
                  <NavLink to={'/#hppc'}>Marketplace</NavLink>
                  <NavLink to={isLoggedIn ? '/sellerform' : '/sellmysurfboard'}>Sell my Surfboard</NavLink>
                  <NavLink to={'/contactus'}>Contact us</NavLink>
                </div>
              </div>
              {isLoggedIn === false ?
                <div className='right-side-content'>
                  <HeaderLogRegBtns />
                    
                  <div className='menu-btn'>
                    <FiMenu onClick={() => setMenuOpt(true)} />

                    <div
                      className='menu-options'
                      // onMouseLeave={() => setMenuOpt(false)} 
                      style={{ display: menuOpt ? "block" : "none" }}
                     
                    >

                      <div className='header-navlinks'>
                      <button className='menuCloseBtn' onClick={(e) => setMenuOpt(false)}>
                        <HiOutlineXMark  />
                      </button>
                        <NavLink to={'/'} className={(e) => e.isActive ? "active" : ""}  onClick={(e) => {console.log(menuOptLogIn); setMenuOpt(false)}}>Home</NavLink>
                        {/* <NavLink to={'/#hppc'} className={(e) => e.isActive ? "active" : ""}>Marketplace</NavLink> */}
                        <NavLink to={'/#hppc'} className={(e) => e.isActive ? "active" : ""}  onClick={(e) => {console.log(menuOptLogIn); setMenuOpt(false)}}>Marketplace</NavLink>
                        <NavLink
                          to={isLoggedIn ? '/sellerform' : '/login'}
                          className={(e) => e.isActive ? "active" : ""}
                          onClick={(e) => {console.log(menuOptLogIn); setMenuOpt(false)}}
                        >
                          Sell my Surfboard
                        </NavLink>
                        <NavLink
                          to={'/contactus'}
                          className={(e) => e.isActive ? "active" : ""}
                          onClick={(e) => {console.log(menuOptLogIn); setMenuOpt(false)}}
                        >
                          Contact us
                        </NavLink>
                        <NavLink to={'/login'} className={(e) => e.isActive ? "active" : ""}  onClick={(e) => {console.log(menuOptLogIn); setMenuOptLogIn(false)}}>Login</NavLink>
                        <NavLink to={'/signup'} className={(e) => e.isActive ? "active" : ""}  onClick={(e) => {console.log(menuOptLogIn); setMenuOptLogIn(false)}}>Register</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                : null}
            </header>
          </div>
      }
    </>
  )
}



export default Header