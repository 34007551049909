import './Product.css';
import { ProfileViewerWithRating } from '../../components';
// import { TfiHeart } from "react-icons/tfi";
// import { IoIosHeart } from "react-icons/io";
// import { TiMessages } from "react-icons/ti";
import { FaDollarSign } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useWishlist } from '../../WishlistContext';
import { GetLoggedInUser } from '../../utils/local-store';
import api from '../../utils/api';
import { imageUrl } from '../../utils/local-store';
import whatsAppImg from '../../assets/images/icons-img/WhatsApp Image 2024-07-31 at 07.12.27_f7fa1d2a.jpg';
import msgImg from '../../assets/images/icons-img/msgImage 2024-07-31 at 07.12.27_5975c854.jpg';
import emailImg from '../../assets/images/icons-img/emailImage 2024-07-31 at 07.12.27_34c1e6cd.jpg';
import { Link } from 'react-router-dom';

const ProductDataSection = ({ productData, changeMainImg }) => {

  console.log(productData);

  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();

  const isInWishlist = wishlist.some(item => item.id === productData.id);

  const currentUser = GetLoggedInUser();
  let userId = 0;
  if (currentUser && currentUser.id > 0) {
    userId = currentUser.id;
  }
  let textwa = '';
  if(productData.product_scf?.length > 0 && productData.product_scf[0].whatsapp?.length > 0) {
    textwa = (`Hi%20${productData.user.last_name}%2C%20${productData.user.first_name}`);
  }
 

  const handleToggleWishlist = () => {
    if (isInWishlist) {
      removeFromWishlist(productData.id);
      api.delete('/wishlist/' + userId + '/' + productData.id).catch((err) => {
        console.log(err);
      });

    } else {
      addToWishlist(productData);
      api.post('/wishlist', { userId: userId, productId: productData.id }).catch((err) => {
        console.log(err);
      });

    }
  };

  const DecimaltoFeet = (val) => {
    console.log('here');
    console.log(val);
    if (val) {
      val = (val * 1).toString();
      let arr = val.split(".");
      if (arr.length == 2) {
        let num = parseInt(arr[1]);
        return arr[0] + "\'" + num;
      } else {
        return arr[0] + "\'" + 0;
      }
    }
    return;

  };

  const widthSet = (val) => {
    if (val) {
      let arr = val.split(".");
      let num = parseInt(arr[1]);
      let temp = '';
      if (num === 25) {
        temp = '1/4';
      } else if (num === 50) {
        temp = '1/2';
      } else if (num === 75) {
        temp = '3/4';
      } else {
        return arr[0];
      }
      return arr[0] + " " + temp;
    }
    return;
  };

  const thicknessSet = (val) => {
    let arr = val.split(".");
    if(arr.length > 1) {
    let num = eval("0." + arr[1]);
    if (num > 0)
      return arr[0] + " " + (num * 16) + "/16";
    else
      return arr[0];
    }
    return arr[0];
  };

  const cityName = (city_name, country_name) => {
    if (city_name?.length > 0) {
      if (country_name?.length > 0) {
        return city_name + ", " + country_name;
      } else {
        return city_name;
      }
    }
  };


  return (
     
    <div className='data-section'>

      <div className='p-v-like-msg-container' >
        <ProfileViewerWithRating rating={4} />
        {/* <div className='sold-out-btn'>
          <button>Sold Out</button>
        </div> */}
        <div className='msg-like-container' >
          <div className='h-m-icons'>
            {console.log(productData.product_scf)}
            {productData.product_scf?.length > 0 && productData.product_scf[0].facebook?.length > 0 ?
             <a href={`https://www.facebook.com/${productData.product_scf[0].facebook}`} target='_blank'><i>
                <img src={msgImg} alt={msgImg} /></i></a> : null}
            {productData.product_scf?.length > 0 && productData.product_scf[0].email?.length > 0 
             ? <Link
             to='#'
             target={'_blank'}
             onClick={(e) => {
                 window.location.href = `mailto:${productData.product_scf[0].email}`;
                 e.preventDefault();
             }}
         ><i>
              <img src={emailImg} alt={emailImg} /></i></Link> : null}
            {productData.product_scf?.length > 0 && productData.product_scf[0].whatsapp?.length > 0
             ?<a href={`//api.whatsapp.com/send?phone=${productData.product_scf[0].whatsapp}&text=${textwa}`} target='_blank'><i><img src={whatsAppImg} alt={whatsAppImg} /></i></a> : null}

            {/* {
              userId && userId > 0 ?

                <i onClick={() => handleToggleWishlist()}>
                  {isInWishlist ? <IoIosHeart style={{ width: '25.5px', height: '25.5px' }} /> : <TfiHeart />}
                </i>
                : null
            } */}
          </div>
          {/* <div className='h-m-icons'>
            <i><TiMessages /></i>
          </div> */}
        </div>
      </div>

      {/* <div className='available-signal'>
        <div className='signal'>
          <i></i><span>Available</span>
        </div>
      </div> */}

      <div className='plp-name-and-rate'>
        <div className='plp-name'>{productData ? productData.board_name : "Product Name..."}</div>
        <div className='plp-rate'>
          <span>{productData ? productData.price : "..."}</span> <FaDollarSign />
        </div>
      </div>

      <div className='plp-product-details'>
        {productData.brand &&
          <div className='a-detail'>
            <span className='detail-name'>Brand</span>
            <span className='detail-value'>{productData.brand.brand_name}</span>
          </div>
        }
        {productData.cities &&
          <div className='a-detail'>
            <span className='detail-name'>Location</span>
            <span className='detail-value'>{cityName(productData.cities?.city_name, productData.cities?.countries?.country_name)}</span>
          </div>
        }
        {productData.size &&
          <div className='a-detail'>
            <span className='detail-name'>Size</span>
            <span className='detail-value'>{DecimaltoFeet(productData.size)}</span>
          </div>
        }
        {productData.volume &&
          <div className='a-detail'>
            <span className='detail-name'>Volume</span>
            <span className='detail-value'>{productData.volume * 1} Liters</span>
          </div>
        }
        {productData.width &&
          <div className='a-detail'>
            <span className='detail-name'>Width</span>
            <span className='detail-value'>{widthSet(productData.width)}</span>
          </div>
        }
        {productData.thickness &&
          <div className='a-detail'>
            <span className='detail-name'>Thickness</span>
            <span className='detail-value'>{thicknessSet(productData.thickness)}</span>
          </div>
        }
        {productData.material &&
          <div className='a-detail'>
            <span className='detail-name'>Material</span>
            <span className='detail-value'>{productData.material}</span>
          </div>
        }
        {productData.year_of_pur &&
          <div className='a-detail'>
            <span className='detail-name'>Year of purchase</span>
            <span className='detail-value'>{productData.year_of_pur}</span>
          </div>
        }
        {productData.fin_setup &&
          <div className='a-detail'>
            <span className='detail-name'>Fin-Setup</span>
            <span className='detail-value'>{productData.fin_setup}</span>
          </div>
        }
        {productData.level_of_surf &&
          <div className='a-detail'>
            <span className='detail-name'>Surf-Level</span>
            <span className='detail-value'>{productData.level_of_surf}</span>
          </div>
        }
        {/* {productData.level &&
          <div className='a-detail'>
            <span className='detail-name'>--</span>
            <span className='detail-value'>{productData.level}</span>
          </div>
        } */}
      </div>


      {/* Image section  */}
      <div className='pp-other-imgs-upper-div pp-other-imgs-upper-div-mobile' >
        <div className='pp-other-imgs'>
          <div className='other-imgs-inner'>
            {
              productData.ProductImages ?
                productData.ProductImages.map((item, index) => {
                  return (
                    <div className='single-img' key={index}>
                      <img
                        src={imageUrl + item.image_name}
                        alt={item.image_name}
                        onClick={() => changeMainImg(item.image_name)}
                      />
                    </div>
                  )
                })

                :
                <>
                  <div className='single-img' ></div>
                  <div className='single-img' ></div>
                  <div className='single-img' ></div>
                </>
            }
          </div>
        </div>
        <div className='pre-next-arrow-icons'>
          <span className='pre-next-arrow-icon'><IoIosArrowBack /></span>
          <span className='pre-next-arrow-icon'><IoIosArrowForward /></span>
        </div>
      </div>


      {productData &&
        <div className='plp-included-item-container'>
          <div className='included-heading'>Included</div>
          <div className='inner-included-item'>
            {productData.is_fin === "Yes" && <div className='a-included-item'>Fins</div>}
            {productData.is_board_bag === "Yes" && <div className='a-included-item'>Surfbag</div>}
            {productData.is_leash === "Yes" && <div className='a-included-item'>Leash</div>}
            {productData.is_surf_stock === "Yes" && <div className='a-included-item'>Surf-Sock</div>}
          </div>
        </div>
      }

      <div className='plp-item-description'>
        <div className='item-description-heading'>Item description</div>
        <div className='inner-item-description'>
          <p>
            {
              productData ?
                productData.description
                :
                "minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim quiblandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper"
            }
          </p>
        </div>
      </div>

    </div>
  )
}

export default ProductDataSection