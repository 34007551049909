import { useState } from 'react';
import PrimaryColorBtn from '../buttons/PrimaryColorBtn';
import Checkbox from '../checkbox/Checkbox';
import './ContactUsForm.css';
import api from '../../utils/api';
import showToastMessage from '../toast-message/ToastMessage';
import validateFields from '../../utils/validation';
import { NavLink } from 'react-router-dom';

const ContactUsForm = () => {

  const [contactFormData, setContactFormData] = useState({
    "fName": "",
    "lName": "",
    "email": "",
    "mobile": "",
    "message": "",
    "agree": false
  })

  const onHandleChange = (event, keyName) => {
    let val;
    if(keyName === 'agree') {
      console.log(event.target.checked);
      val = event.target.checked?true:false;
    } else {
      val = event.target.value;
    }
    setContactFormData({ ...contactFormData, [keyName]: val })
    console.log(contactFormData);
  }

  const submitForm = (event) => {
    try {
        const fields = [
          { value: contactFormData.fName, validationType: 'required', keyName: 'First Name' },
          { value: contactFormData.lName, validationType: 'required', keyName: 'Last Name' },
          { value: contactFormData.email, validationType: 'required', keyName: 'Email' },
          { value: contactFormData.message, validationType: 'required', keyName: 'Message' },
          { value: contactFormData.agree, validationType: 'checkbox', keyName: 'You agree' },
      ];
      //   console.log(validateFields(fields)); // Output: true
      if (validateFields(fields)) {
        const response = api.post('/contactus', { contactFormData });
        showToastMessage("Admin will contact you shortly!", "success");
      }

    } catch (error) {
    console.log(error);
    let errorMessage = error.response.data.message || error.message;
    showToastMessage(errorMessage, "error");

  }
  }

  const checkboxPrivacy = "By submitting, you agree to our Privacy Policy and consent to receive updates from us.";

  return (
    <form className='contect-us-form'>
      <div className='c-u-f-name-section'>
        <input
          type='text'
          placeholder='First Name'
          onChange={(event) => onHandleChange(event, "fName")}
        />
        <input
          type='text'
          placeholder='Last Name'
          onChange={(event) => onHandleChange(event, "lName")}
        />
      </div>
      <div className='c-u-f-email-phone-section'>
        <input
          type='email'
          placeholder='E-Mail'
          onChange={(event) => onHandleChange(event, "email")}
        />
        <input
          type='mobile'
          placeholder='Phone'
          onChange={(event) => onHandleChange(event, "mobile")}
        />
      </div>
      <div className='c-u-f-textarea-section'>
        <textarea
          placeholder='Message us'
          rows={10}
          onChange={(event) => onHandleChange(event, "message")}
        />
      </div>
      <div className='c-u-f-confirm-checkbox-section'>
        <Checkbox
          name={
            <>
            <sapn className='lbl-privacy-policy'> By submitting, you agree to our 
            &nbsp;<NavLink to={"/privacy-policy"} target='_blank'>Privacy Policy</NavLink>&nbsp;
            and consent to receive updates from us.</sapn>
            </>
          }
         
          onClick={(event) => onHandleChange(event, "agree")}
          value={true}
        />
      </div>
      <div className='c-u-f-btn-section'>
        <PrimaryColorBtn text={'Send Message'} onClick={(event) => submitForm(event)}/>
      </div>
    </form>
  )
}

export default ContactUsForm