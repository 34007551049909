import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import {
  HomePage,
  LoginPage,
  SignUpPage,
  SignUpWithEmail,
  Dashboard,
  ChatsPage,
  ChatBoxPage,
  SellerFormPage,
  SellerProfilePage,
  Product,
  ProductListingPage,
  SuccessPage,
  FilterPage,
  PageNotFound,
  ContactUs,
  Saved,
  AccountSettings,
  TermsOfUse,
  DataPrivacy,
  SellMySurfboard,
  Imprint,

} from './pages';
// import style from './assets/styles/style.css';
import { useState, useEffect } from 'react';

import { Footer, Header, Layout } from './template';
import { IsUserLoggedIn } from './utils/local-store';

// import { ToastProvider } from 'react-toast-notifications';
// import  { MyCustomToast }  from './components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckRefreshToken } from './utils/refresh-token';
import { CookieConsent } from './components';
import { useCookies } from 'react-cookie';


function App() {

  const isLoggedIn = IsUserLoggedIn();
  //console.log(isLoggedIn);
  const [userRegister, setUserRegister] = useState(false);



  const logedIn = (inOut) => {
    setUserRegister(inOut);
  }

  CheckRefreshToken();

  const [cookies] = useCookies(["cookieConsent"]);

 

  return (
    <BrowserRouter>
      {
        <>
          <div className={isLoggedIn && 'app-main-container'}>
            <Layout isLoggedIn={isLoggedIn} />
            {isLoggedIn ?
              <main>
                <div className='page-content'>
                  <Header isLoggedIn={isLoggedIn} />
                  <Routes>                    
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path='/sellerform' element={<SellerFormPage />} />
                    <Route path='/chats' element={<ChatsPage />} />
                    <Route path='/chatbox' element={<ChatBoxPage />} />
                    <Route path='/sellerProfile' element={<SellerProfilePage />} />
                    <Route path='/product/:id' element={<Product />} />
                    <Route path='/products' element={<ProductListingPage />} />
                    <Route path='/products?q' element={<ProductListingPage />} />
                    <Route path='/filter' element={<FilterPage />} />
                    <Route path='/successful' element={<SuccessPage />} />
                    <Route exact path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/saved" element={<Saved />} />
                    <Route path="/settings" element={<AccountSettings />} />
                    <Route path="*" element={<PageNotFound isLoggedIn={isLoggedIn} />} />
                  </Routes>
                </div>
              </main>
              :
              <div className='inter-normal'>
                 { window.location.pathname.indexOf('/product/') >= 0?<Header isLoggedIn={isLoggedIn} />:null }
                <Routes>
                  <Route path='/' element={<HomePage userRegister={userRegister} />} />
                  <Route path='/signup' element={<SignUpPage logedIn={logedIn} />} />
                  <Route path='/sellmysurfboard' element={<SellMySurfboard />} />
                  <Route path='/product/:id' element={<Product />} />
                  <Route path='/signupwithemail' element={<SignUpWithEmail />} />
                  <Route path='/login' element={<LoginPage />} />
                  <Route path='/contactus' element={<ContactUs />} />
                  <Route path='/terms-and-conditions' element={<TermsOfUse />} />
                  <Route path='/privacy-policy' element={<DataPrivacy />} />
                  <Route path='/imprint' element={<Imprint />} />
                  <Route path="*" element={<PageNotFound isLoggedIn={isLoggedIn} />} />
                </Routes>
                <Footer />
              </div>
            }
          </div>
          
        </>

      }
      <ToastContainer />
      {!cookies.cookieConsent && <CookieConsent />}
      
      
    </BrowserRouter>
  );
}

export default App;
