import './SizeRange.css';

const SizeRange = ({ min, max, minSizeFeet, minSizeInch, maxSizeFeet, maxSizeInch, 
  minSizeHandleChange, maxSizeHandleChange }) => {
  const minLabelStyle = {
    position: 'absolute',
    left:   '0px',
    top:'25px'
  }
  const maxLabelStyle = {
    position: 'absolute',
    left: '385px',
    top:'25px'
  }
  return (
    <div className='size-range-container'>

      <div className='filter-page-lable-of-input'>Size Range</div>

      <div className="range_container">
        <div className="sliders_control">
          <div className='c-s-s-tumb-lable'>
            <label style={minLabelStyle}>{minSizeFeet + "'" + minSizeInch}</label>
          </div>
          <input id="fromSlider" type="range" min={min} max={max} value={minSizeFeet * 12 + minSizeInch} onChange={       minSizeHandleChange} />
          <div className='c-s-s-tumb-lable'>
            <label style={maxLabelStyle}>{maxSizeFeet + "'" + maxSizeInch}</label>
          </div>
          <input id="toSlider" type="range" min={min} max={max} value={maxSizeFeet * 12 + maxSizeInch} onChange={maxSizeHandleChange} />
        </div>
      </div>

    </div>
  )
}

export default SizeRange