// Chats Page 
import './ChatsPage.css';
import {
  ChatBoxStoryContainer, ProfileViewer
} from '../../components';
import { FaPlus } from "react-icons/fa6";
import { CiUser } from "react-icons/ci";
import { GoDotFill } from "react-icons/go";
import { ProfileIcon } from '../../assets/icons';
import { NavLink } from 'react-router-dom';
import api from '../../utils/api';
import { useState, useEffect } from 'react';
import { GetLoggedInUser } from '../../utils/local-store';

const ChatsPage = () => {
  const senderId = GetLoggedInUser().id
  const [friends, setFriends] = useState([]);


  useEffect(() => {

    api.get('/messages/getConnections/' + senderId)
      .then(response => {
        setFriends(response.data);
        
      })
      .catch(error => {
        console.error('Error fetching messages:', error);
      });
  }, []);

  return (
    <div className='chats-page-container'>

      <div className='content-heading'>
        <h2>Chats</h2>
        <div className='c-h-profile-viewer'>
          <ProfileViewer />
        </div>  
      </div>

      <div className='user-story-container'>
        <div className='user-story-slider'>
          <div className='my-story'>
            <ChatBoxStoryContainer />
            <span className='pls-icon-for-add-story'>
              <div>
                <FaPlus />
              </div>
            </span>
          </div>
        {
          friends && friends.map((item, index) => {
              console.log(item);
             return(<div className='user-story'><ChatBoxStoryContainer username={item.user.user_name}/></div>)
          })
        }
          
          
        </div>
      </div>

      <div className='content-body'>
        <div className='inbox-grid'>  

          {/* first user chat */}
          <NavLink to={'/chatbox'}>
            <div className='a-message-row-container'>
              <div className='a-msg-r-c-left-part'>
                <div className='amrc-user-img'>
                  <span>
                    <ProfileIcon />
                  </span>
                  <div className='typing-active'>
                    <div className='typing-active-inner'>
                      <GoDotFill style={{ color: 'rgba(234, 84, 98, 0.2)' }} />
                      <GoDotFill style={{ color: 'rgba(234, 84, 98, 1)' }} />
                      <GoDotFill style={{ color: 'rgba(234, 84, 98, 0.5)' }} />
                    </div>
                  </div>
                </div>

                <div className='amrc-user-name-n-last-msg'>
                  <h4>Profilename</h4>
                  <p>Hi, Awesome</p>
                </div>

              </div>
              <div className='a-msg-r-c-right-part'>
                <div><span className='notify-signle'>5</span></div>
                <p>Today, 12:15</p>
              </div>
            </div>
          </NavLink>

          
          
        
        
        </div>
      </div>

    </div>

  )
}

export default ChatsPage;