// import { HeroSectionBtns, HeroSectionReviews } from '../../../components';
import { HeroSectionReviews } from '../../../components';
import './HeroSection.css';
// import { FaArrowRight } from "react-icons/fa6";
// import iPhoneImg from '../../../assets/images/iPhoneMockup.png'
import bannerImg from '../../../assets/images/beforeregisterbanner.svg';
import HeroSectionBtn from './HeroSectionBtn';


const HeroSection = () => {
  return (
    <div className="hero-section-component">

      <div className="h-s-text-section">

        {/* <div className="any-new-msg">
          <span className='border'>
            <i></i>
            <span>LIVE</span>
          </span>
          <span className='arrow-icon'>
            28’917 BUYERS ARE LOOKING FOR A BOARD
            <FaArrowRight />
          </span>
        </div> */}

        <div className="hero-section-heading">
        Frustrated with <br/> Facebook Marketplace <br/> for Surfboards?
        </div>

        <div className="hero-section-p1">
        Sell Your Surfboard Faster and Easier on Surfpoint!
        </div>

        <div className="hero-section-p2">
        Tired of the endless back-and-forth on Facebook Marketplace? List your surfboard on Surfpoint and reach a dedicated community of surf enthusiasts who are ready to buy. Our platform is designed to make selling easy – with targeted listings, instant notifications, and no hidden fees. 
        </div>

        <div className="hero-section-p1">
        Get more eyes on your board and sell it faster, without the hassle!
        </div>

        <div className='hero-section-btns'>
          <HeroSectionBtn />
        </div>

        <div className='hero-section-reviews'>
          <HeroSectionReviews />
        </div>

      </div>

      <div className="h-s-img-section">
        <div className='h-s-img-section-inner'>

          <div className='h-s-i-s-blob'></div>

          <div className='h-s-imgs'>

            {/* <div className='iphone-img'>
              <img src={iPhoneImg} alt='iphone.png' />
            </div> */}

            <div className='a-man-banner-img'>
            <img src={bannerImg} alt='banner.svg' />
            </div>

          </div>

        </div>
      </div>

    </div>
  )
}

export default HeroSection