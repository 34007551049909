import { NavLink } from 'react-router-dom';
import './Buttons.css';


const HeaderLogRegBtns = () => {
  return (
    <div className='log-reg-btns'>

      <NavLink to={'/login'} className={'navlink'}>
        <button className='btn1'>Login</button>
      </NavLink>

      <NavLink to={'/signup'} className={'navlink'}>
        <button className='btn2'>Register</button>
      </NavLink>


    </div>
  )
}

export default HeaderLogRegBtns