import React from 'react'
import './ChangePassword.css'

const ChangePassword = () => {
  return (
    <div className='change-password-container'>
      <div className='profile-setting-heading-and-btn'>
        <h2>Change Password</h2>
        <button>Update Password</button>
      </div>
      <form>
        <input type='text' placeholder='Existing Password'/>
        <input type='text' placeholder='New Password'/>
        <input type='text' placeholder='Confirm New Password'/>
      </form>
    </div>
  )
}

export default ChangePassword