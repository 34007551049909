// Products Listing Page 
import './ProductListingPage.css';
import {
  AProduct
} from '../../components';
import AddFilterPopup from '../../components/popup/Popup';
import { useEffect, useState } from 'react';
import { FaLongArrowAltLeft, FaLongArrowAltRight  } from "react-icons/fa";
import api from '../../utils/api';
import { useLocation } from 'react-router-dom'; 
import { MyContext } from "./MyContext";



const getQueryParams=t=>{if(!t||"string"!=typeof t||t.length<2)return new Map;const r=t.substr(1).split("&").map(t=>{const r=t.split("=");return[r[0],r[1]]});return new Map(r)};
   


const ProductListingPage = () => {

  const [products, setProducts] = useState([]);
  const [isFetched, setIsFetched] = useState(false); 
  const s = useLocation().search;
  console.log(s);
  const m = getQueryParams(s);
  console.log(m);

  const query  = decodeURI(m.get('q') || '');
  console.log(query);





  const [filterInputs, setFilterInputs] = useState({
    "min_price": "",
    "max_price": "",
    "min_size": 60,
    "max_size": 120,
    "brand_ids": [],
    "min_volume": "",
    "max_volume": "",    
    "location": "",
    "isClose": false,
    "isReset": false
  });

  const [brands, setBrands] = useState({});
  const [locations, setLocations] = useState({});

  useEffect(() => {
    const fetchProducts = async (search) => {
      
     
      // const postSearch = {
      //   cityName: cityName,
      //   boardingTypes: state.boarding_types,
      //   subTypes: state.school_level,
      //   Boards: state.school_boards,
      //   locations: state.locations,
      //   approx_fees: state.school_fees,
      //   cp: 'search',
      //   page: 1,
      //   limit: 5
      // }

      const response = await api.post("/products/getAll", {
        page: 1, 
        limit: 200,
        s: search
      });
     if(response != null || response != undefined) {
        setProducts(response.data);
     }
    };

    fetchProducts(query);
  }, [query]);

  useEffect(() => {
      async function fetchData(filterData) {

        if(filterData.min_size >= 60 && filterData.min_size <= 120) {
          filterData.min_size /= 12;
        }
        if(filterData.max_size >= 60 && filterData.max_size <= 120) {
          filterData.max_size /= 12;
        }
        const response = await api.post("/products/getAll", {
          page: 1, 
          limit: 20,
          filter: filterData
        });
        setProducts(response.data);
      }
      console.log(filterInputs);
      if(filterInputs.isClose) {
        fetchData(filterInputs); 
        setFilterInputs({...filterInputs, ['isClose']: false, ['isReset']: false});
      }

      
      
  }, [filterInputs]);



  useEffect(() => {
    const fetchBrands = async () => {
      const response = await api.get("/brands/");
       if(response.data.length > 0) {
         let options = await response.data.map((x) => { return { value: x.id , label: x.brand_name} });
    
         setBrands(options);

       }
      
    };
    const fetchLocations = async () => {
      const locationsResponse = await api.get("/locations/");
       if(locationsResponse.data.length > 0) {
         let options = await locationsResponse.data.map((x) => { return { value: x.id , label: x.countries == null?x.city_name:x.city_name + ", " + x.countries.country_name } });
         setLocations(options);

       }  
      
    };
    if(!isFetched) {
      setIsFetched(true);
      fetchBrands();
      fetchLocations();
      
    }  
  }, [isFetched]);


  // Pagination Code Here 
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = products && products.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(products && products.length / recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1)
 
  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    } else {
      return 0;
    }
  }

  const changeCPage = (id) => {
    setCurrentPage(id)
  }

  const nextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    } else {
      return 0; 
    }
  }
  
  

 
  
  return (
    <>
      <div className='content-body product-listing-page-content-body'>
        <div className='products-listing'>

          <div className='products-heading-section'>
            <h2>Suggested to you</h2>
            <MyContext.Provider value={{filterInputs, setFilterInputs, brands, locations}}>
              <AddFilterPopup />
            </MyContext.Provider>
          </div> 

          <div className='products-container-section'>

            <div className='products-row'>
              {
                records && records.map((item, index) => <AProduct product={item}  key={item.id}/>)
              }
            </div>

            <div className='pagination-container'>

              <button onClick={prePage} className='pre-btn'><FaLongArrowAltLeft />Prev</button>

              {
                numbers.map((item, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => changeCPage(item)}
                      className={currentPage === item ? 'active pg-num' : 'pg-num'}
                    >
                      {item}
                    </button>
                  )
                })
              }

              <button onClick={nextPage} className='nxt-btn'>Next <FaLongArrowAltRight /></button>

            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default ProductListingPage