import React from 'react';
import "./SideBar1.css";
import {
  AccountSettingsIcon,
  DashboardIcon,
  SavedIcon,
  SellSurfboardIcon,
  SellSurfboardIcon2,
  BrowseMarkeplaceIcon,

} from '../../assets/icons/sidebar-icons';
import { Logo, Logout } from '../../components';
import { NavLink } from 'react-router-dom';

const SideBar1 = () => {

  const topMenuItem = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <DashboardIcon />,
      notify: false,
    },
    {
      path: '/sellerform',
      name: 'Sell Surfboard',
      icon: <SellSurfboardIcon2 />,
      notify: true,
    },
    // {
    //   path: '/chats',
    //   name: 'Chats',
    //   icon: <ChatsIcon />,
    //   notify: false,
    // },
    // {
    //   path: '/saved',
    //   name: 'Saved',
    //   icon: <SavedIcon />,
    //   notify: false,
    // },
    {
      path: '/products',
      name: 'Browse Marketplace',
      icon: <BrowseMarkeplaceIcon />,
      notify: false,
    },
  ]

  return (
    <div className='sidebar-main-container'>
      <div>
        <div>
          <Logo />
        </div>
        <div className='sidebar-container'>
          <div className='inner-sidebar-container'>
            <div className='sidebar-header-item'>
              {
                topMenuItem.map((item, index) => {
                  return (
                    <NavLink
                      to={item.path}
                      key={index}
                      className={index === 0 ? "sidebar-element sb-opt-font " : "sidebar-element sb-opt-font"}
                    >
                      {item.icon}
                      <p>{item.name}</p>
                      {item.notify && <span className='notify-dot'></span>}
                    </NavLink>
                  )
                })
              }
            </div>
            <div className='sidebar-footer-item'>
              <NavLink to={'/settings'} className="navlink">
                <div className="sidebar-element sb-opt-font">
                  <AccountSettingsIcon />
                  <p>Account Settings</p>
                </div>
              </NavLink>
              <Logout />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar1