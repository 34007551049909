import React, { useState } from "react";
import axios from "axios";
import HeroSection from './heroSection/HeroSection';
import './HomePage.css';
import { Header } from '../../template';
import ProductSection from './productSection/ProductSection';
import AddFilterPopup from "../../components/popup/Popup";
import { Link } from 'react-router-dom';

const HomePage = () => {
 
  return (
    <>
     
      <main className="home-main-container">

        <div className="hero-section">
          <HeroSection />
        </div>

        <div className="home-page-filter-btn-section">
          <div className="h-p-f-b-s-inner">
            <div className="h-p-f-b-s-i-text">Discover secondhand surfboards in minutes – not hours!</div>
            <div className="h-p-f-b-s-i-btn">
            <Link to="login">Add Filter and save time</Link>
            </div>
          </div>
        </div>

        <div className='home-page-products-container' id="hppc">
          <div className='h-p-p-c-inner'>
            <ProductSection />
          </div>
        </div>

      </main>
    </>
  )
}

export default HomePage