import './HeroSectionReviews.css';
import {
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
} from '../../assets/images/userAvtars';
import HeroSectionStarRating from '../starRating/heroSectionStarRating/HeroSectionStarRating';

const usersReviews = [
  {
    id: 1,
    name: "user.img",
    img: Avatar1,
  },
  {
    id: 2,
    name: "user.img",
    img: Avatar2,
  },
  {
    id: 3,
    name: "user.img",
    img: Avatar3,
  },
  {
    id: 4,
    name: "user.img",
    img: Avatar4,
  },
  {
    id: 5,
    name: "user.img",
    img: Avatar1,
  },
  {
    id: 6,
    name: "user.img",
    img: Avatar3,
  },
  {
    id: 7,
    name: "user.img",
    img: Avatar2,
  },
  {
    id: 8,
    name: "user.img",
    img: Avatar1,
  },
]

const HeroSectionReviews = () => {
  let count = 1;
  return (
    <div className='h-s-reviews'>

      <div className='users-imgs'>
        {usersReviews && usersReviews.map((elem, index) => {
          if (count <= 7) {
            count++;
            return (
              <div className='a-user-img' key={index}>
                <img src={elem.img} alt={elem.name} />
              </div>
            )
          }
        })}
      </div>

      <div className='reviews-star-and-text'>

        <div className='rating-star'>
            <HeroSectionStarRating rating={4.9}/>
        </div>

        <div className='rating-text'>
          From 200+ Reviews
        </div>

      </div>

    </div>
  )
}

export default HeroSectionReviews