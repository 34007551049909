// Chats Page 
import './ChatBoxPage.css';
import {
  ChatBoxProfileViewer,
  ProfileViewer,
  SearchIcon,
  SendMsgIconBtn,
  Sidebar
} from '../../components';
import { FaCirclePlus } from "react-icons/fa6";
import api from '../../utils/api';
import { useState, useEffect } from 'react';
import { FormatDate } from '../../utils/helpers';

const ChatBoxPage = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const senderId = 1; // Assume current user is sender
  const receiverId = 2; // ID of the user you are chatting with
  let msgClass = '';

  useEffect(() => {
    api.get(`/messages/${senderId}/${receiverId}`)
      .then(response => {
        setMessages(response.data);
        //console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching messages:', error);
      });
  }, [senderId, receiverId]);

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api.post('/messages', { senderId, receiverId, message: newMessage })
      .then(() => {
        setNewMessage('');
      })
      .catch(error => {
        console.error('Error sending message:', error);
      });
  };

  return (

    <>
      <div className='content-heading'>
        <h2>Chats</h2>
      </div>

      <div className='content-body content-body-chatbox'>

        <div className='chatbox-p-v'>
          <ChatBoxProfileViewer />
        </div>

        <div className='text-messages'>
          {

            messages && messages.map((item, index) => {
              msgClass = item.sender_id === receiverId ? 'message-recieved' : 'message-sent';
              return (
                <div className={msgClass} key={index}>
                  <p>
                    {item.message}
                  </p>
                  <p className='tym'>{FormatDate(item.createdAt)}</p>
                </div>
              );
            })
          }
          {/* <div className='message-sent'>
            <p>Hey! Sure thing. The surfboard is in great shape, asking $300. Interested?</p>
            <p className='tym'>12:15 &#10003;</p>
          </div> */}


        </div>

        <div className='msg-input-container'>
          <form onSubmit={handleSubmit}>
            <div className='msg-input'>
              <div>
                <span>
                  <FaCirclePlus />
                </span>
                <input type='text' value={newMessage} onChange={handleMessageChange} />
              </div>
            </div>
            <div className='msg-send-btn'>
              <SendMsgIconBtn />
            </div>
          </form>
        </div>

      </div>
    </>
  )
}

export default ChatBoxPage;