import './Checkbox.css';

const Checkbox = ({ name, value,  onClick }) => {
  return (
    <span className='checkbox'>
        <label className="label-container">
          <input type="checkbox" value={value}  onClick={onClick}/>
          <span className="checkmark" ></span>
          {name}
        </label>
    </span>
  )
}

export default Checkbox