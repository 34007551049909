import './ProfileIconPV.css';
import { ProfileIcon } from '../../assets/icons';
import { NavLink } from 'react-router-dom';

const ProfileIconPV = () => {
  return (
    <NavLink to={'/settings'} className={'navlink'}>
      <div className='profile-icon-container'>
        <ProfileIcon />
      </div>
    </NavLink>
  )
}

export default ProfileIconPV