import './SignUpPage.css';
import { NavLink, useNavigate } from 'react-router-dom';
import googleImg from "../../assets/images/google.svg";
import { FaFacebookF } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import bannerImg from '../../assets/images/beforeregisterbanner.svg';
import { Logo } from '../../components';

const SignUpPage = ({ logedIn }) => {

  const navigate = useNavigate();

  return (
    <>
      <div className='login-page-logo'><Logo /></div>
      <div className='container'>
        <div className='grid grid-two-column'>
          <div className='image-container'>
            <div className='banner-img'>
              <img src={bannerImg} alt='banner.png' />
            </div>
          </div>

          <div className='form-container'>
            <div className='inner-form-container'>
              <h2>Sign up</h2>
              <p>Create your account to access our exclusive secondhand surfboard marketplace. Whether you're selling or buying—it's free!</p>

              <div className="grid" style={{ gap: '12px', padding: '14px 0' }}>

                {/* <button className="icon-btn sing-up-with-google-btn" onClick={() => logedIn(true)}>
                  <img src={googleImg} alt='google.svg' width="12px" />
                  Sign up with Google
                </button>

                <button className="icon-btn icon-btn-facebook sing-up-with-fb-btn">
                  <FaFacebookF style={{ height: '1rem', marginTop: '-2px' }} />
                  Sign up with Facebook
                </button> */}

                <button className="icon-btn icon-btn-email">
                  <AiOutlineMail style={{ width: "1.1rem", height: "1.1rem" }} />
                  <NavLink to={"/signupwithemail"} >
                    Sign up with E-Mail
                  </NavLink>
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mobile-container'>
        <div className='m-c-inner'>
          <div className='m-c-i-heading-container'>
            <h2>Sign up</h2>
            <p>Create your account to access our exclusive secondhand surfboard marketplace. Whether you're selling or buying—it's free!</p>

          </div>
          <div className='m-c-i-image-container'>
            <div className='banner-img'>
              <img src={bannerImg} alt='banner.png' />
            </div>
          </div>
          <div className='m-c-i-btns-container'>
            {/* <button className="icon-btn icon-btn-google" onClick={() => logedIn(true)}>
              <img src={googleImg} alt='google.svg' width="12px" />
              Sign up with Google
            </button>
            <button className="icon-btn icon-btn-facebook">
              <FaFacebookF style={{ height: '1rem', marginTop: '-2px' }} />
              Sign up with Facebook
            </button> */}
            <button className="icon-btn icon-btn-email">
              <AiOutlineMail style={{ width: "1.1rem", height: "1.1rem" }} />
              <NavLink to={"/signupwithemail"} >
                Sign up with E-Mail
              </NavLink>
            </button>
          </div>
        </div>
      </div>

    </>
  )
}

export default SignUpPage