import './Buttons.css';

const PrimaryColorBtn = ({ padding, text, width, onClick }) => {
  return (
    <button onClick={onClick} type='button'
      className='primary-clr-btn'
      style={{
        padding: padding,
        width: width,
      }}
    >
      {text}
    </button>
  )
}

export default PrimaryColorBtn