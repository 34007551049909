import './DashboardProfileViewer.css';
import {ProfileIcon} from '../../assets/icons';
import { useEffect, useState } from 'react';
import { GetLoggedInUser } from '../../utils/local-store';


const DashboardProfileViewer = () => {
  const [userName, setUserName] = useState('');
  let user = GetLoggedInUser();

  useEffect(() => {
    setUserName(user.name);

  }, [user]);
  return (
    <>
      <div className='dasboard-profile-viewer'>
        <div className='user-img-DBPPV '>
          <i>
            <ProfileIcon />
          </i>
        </div>
        <div className='user-name-DBPPV'>
          <h4>@{userName}</h4>
        </div>
      </div>
    </>
  )
}

export default DashboardProfileViewer