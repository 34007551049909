import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { FilterPage } from '../../pages';
import { FilterIcon } from '../../assets/icons';

const AddFilterPopup = ({ btnName = "Add Filter" }) => {
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  }
  const MODAL_STYLES = {
    position: "absolute",
    backgroundColor: "#FFF",
    padding: "15px",
    zIndex: "1000",
    borderRadius: ".5em",
    height: "100%",
  };
  const OVERLAY_STYLE = {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    overflowY: "auto"
  };
  return (
    <div>
      {/* <button type="button" className="button" onClick={() => setOpen(o => !o)}>
        Controlled Popup
      </button> */}
      <div className='filter-btn'>
        {/* <button onClick="">Reset Filter</button> */}
        <button onClick={() => setOpen(o => !o)}><FilterIcon />{btnName}</button>
      </div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal} lockScroll={true} >
        <div className="modal" style={OVERLAY_STYLE} >
          {/* <a className="close" onClick={closeModal}>
            &times;
          </a> */}
          <FilterPage closeModal={closeModal} style={MODAL_STYLES} />
        </div>
      </Popup>
    </div>
  );
};

export default AddFilterPopup