

const StarIcon = ({ color }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.90526 0.612793L7.23108 4.69323H11.5215L8.05048 7.21508L9.37629 11.2955L5.90526 8.77367L2.43424 11.2955L3.76005 7.21508L0.289024 4.69323H4.57945L5.90526 0.612793Z" fill={color} />
    </svg>
  )
}

export default StarIcon