import React, { createContext, useState, useContext } from 'react';
import { GetLoggedInUser } from './utils/local-store';
import api from './utils/api';

// Create the context
const WishlistContext = createContext();

// Create a provider component
export const WishlistProvider = ({ children }) => {
  
  const [wishlist, setWishlist] = useState([]);

  const addToWishlist = (product) => {
    setWishlist(prevWishlist => [...prevWishlist, product]);
    
  };

  const removeFromWishlist = (productId) => {
    setWishlist(prevWishlist => prevWishlist.filter(item => item.id !== productId));

  };

  // Function to synchronize wishlist with database
 const SyncWishlist = async () => {
  try {
    // Make API request to fetch wishlist data from the server
    const userId = GetLoggedInUser().id;
    const response = await api.get('/wishlist/' + userId);
    setWishlist(response.data); // Update local wishlist state
  } catch (error) {
    console.error('Error syncing wishlist:', error);
  }
};

  return (
    <WishlistContext.Provider value={{ wishlist, SyncWishlist, addToWishlist, removeFromWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useWishlist = () => {
  return useContext(WishlistContext);
};
