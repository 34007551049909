import './StarRating.css';
import StarIcon from './starIconSvg/StarIcon';

const StarRating = ({ rating=3 }) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<StarIcon key={i} color={'#ff5767'} />);
      } else {
        stars.push(<StarIcon key={i} color={'lightgray'} />);
      }
    }
    return stars;
  };

  return (
    <div className='star-rating'>
      {renderStars()}
      <span>({rating})</span> 
    </div>
  )
};

export default StarRating;