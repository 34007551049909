import { IsUserLoggedIn } from '../../../utils/local-store';
import './HeroSectionBtns.css';
import { NavLink } from 'react-router-dom';

const HeroSectionBtns = () => {
  const isLoggedIn = IsUserLoggedIn();
  return (
    <div className='sell-and-explore-btn'>
      <NavLink to={isLoggedIn ? '/sellerform' : '/login'}><button className='f-btn'>Sell My Surfboard</button></NavLink>
      <a href={'#hppc'}><button className='s-btn'>Explore Surfboards</button></a>
    </div>
  )
}

export default HeroSectionBtns