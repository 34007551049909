import './AProduct.css';
import { FaEuroSign } from "react-icons/fa";
import { TfiHeart } from "react-icons/tfi";
import { IoIosHeart } from "react-icons/io";
import { NavLink } from 'react-router-dom';
import api from '../../utils/api';
import { useWishlist } from '../../WishlistContext';
import { GetLoggedInUser } from  '../../utils/local-store';
import { imageUrl } from '../../utils/local-store';
import defaultImg from '../../assets/images/default-img.jpg';

const AProduct = ({ product }) => {


  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();

  const isInWishlist = wishlist.some(item => item.id === product.id);

  let image_name = product.ProductImages.length >0? imageUrl + product.ProductImages[0].image_name: defaultImg;

  const userId = GetLoggedInUser().id;

  const handleToggleWishlist = () => {
    if (isInWishlist) {
      removeFromWishlist(product.id);
      api.delete('/wishlist/' + userId + '/' + product.id).catch((err) => {
        console.log(err);
      });
      
    } else {
      addToWishlist(product);
      api.post('/wishlist', { userId: userId, productId: product.id }).catch((err) => {
        console.log(err);
      });
  
    }
  };

  
  return (
    <div className='a-product-container'>
      <div className='product-img'>
      <NavLink to={`/product/${product.id}`} className={"navlink"}>
        <img 
          src={image_name} 
          alt={image_name} 
        />
      </NavLink>
        <span onClick={() =>handleToggleWishlist()}>
          {isInWishlist  ? <IoIosHeart style={{ fontSize: '20px' }} /> : <TfiHeart />}
        </span>
      </div>
      <NavLink to={`/product/${product.id}`} className={"navlink"}>
        <div className='product-name-and-price'>
          <div className='product-name'>{product.name}</div>
          <div className='product-price-and-owner-name'>
            <p className='ow-name'>@{product.user.user_name}</p>
            <p className='price'>
              <span>{product.price} $</span>
              {/* <FaEuroSign /> */}
            </p>
          </div>
        </div>
      </NavLink>
    </div>
  )
}

export default AProduct