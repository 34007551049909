import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetLoggedInUser } from '../../utils/local-store';
import validateFields from '../../utils/validation';
import api from '../../utils/api';
import { loginSuccess, loginFailure, logout } from '../../auth/actions/authActions'
import showToastMessage from '../toast-message/ToastMessage';
import './ProfileSetting.css';

const ProfileSetting = () => {
  let userData = GetLoggedInUser()
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  useEffect(()=>{
    
    setUser({
      "first_name": userData.first_name,
      "last_name": userData.last_name,
      "email": userData.email,
      "user_name": userData.name,
      "id": userData.id
    })
  }, [userData]);


  const saveProfile = async () => {
    try {
      console.log(user);
      //return;
      const fields = [
        { value: user.first_name, validationType: 'required', keyName: 'First Name' },
        { value: user.last_name, validationType: 'required', keyName: 'Last Name' },
        { value: user.email, validationType: 'required', keyName: 'Email' },
        { value: user.user_name, validationType: 'required', keyName: 'UserName' },
       
      ];
      
      //   console.log(validateFields(fields)); // Output: true
      if (validateFields(fields)) {
        await api.post('/auth/account-settings', user).then(async (resp) => {
          const { user, accessToken, refreshToken, expiryTime } = resp.data;
          //dispatch(loginSuccess({ user, accessToken, refreshToken, expiryTime }));
          showToastMessage("your profille data successfully updated", "success");
          dispatch(logout());
          window.localStorage.clear();
          window.location.replace("/");   
          
        });

        //dispatch(loginSuccess({ user, accessToken, refreshToken, expiryTime }));
        // Redirect to another page
        //; navigate('/successful'); // Replace '/dashboard' with the desired page path
        // navigate('/sellerProfile');
      }
    } catch (error) {
      console.log(error);
      // let errorMessage = error.response.data.message || error.message;
      // showToastMessage(errorMessage, "error");
      // dispatch(loginFailure());
    }
  };


  return (
    <div className='profile-setting-container'>
      <div className='profile-setting-heading-and-btn'>
        <h2>Profile Settings</h2>
        <button type='button' onClick={() => saveProfile()}>Save</button>
      </div>
      <form>
        <div>
          <div>
            <label >First Name</label>
            <input type='text' placeholder='First Name'  value={user.first_name} onChange={(e) => setUser({...user, "first_name":e.target.value})}/>
          </div>
          <div>
            <label >Last Name</label>
            <input type='text' placeholder='Last Name' value={user.last_name} onChange={(e) => setUser({...user, "last_name":e.target.value})} />
          </div>
        </div>
        <div>
          <div>
            <label >E-Mail </label>
            <input type='text' placeholder='E-Mail' value={user.email} onChange={(e) => setUser({...user, "email":e.target.value})} />
          </div>
          <div className='change-username-field'>
            <label>Username</label>
            <input type='text' placeholder='Username' value={user.user_name} onChange={(e) =>setUser({...user, "user_name":e.target.value})} />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProfileSetting