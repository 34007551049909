import './Dashboard.css';
import { DashboardProfileViewer } from '../../components';
import { FaPlus } from "react-icons/fa6";
import { ChatsIcon, SellSurfboardIcon2 } from '../../assets/icons/sidebar-icons';
import SurfpointLogo from '../../assets/images/SurfpointLogo.svg';
import { NavLink } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div className='content-body' style={{ marginTop: '4rem' }}>

      <div className='dashboard-p-v'>
        <DashboardProfileViewer />
      </div>

      <div className='dashboard-first-row'>
        <div className='inner-db-fr'>


          <div className='db-fr-elem'>
            <NavLink to={'/sellerform'} className={'navlink'}>
              <div className='db-fr-content-container'>
                <h4>Sell my Surfboard</h4>
                <div className='db-fr-icon plus-icon'>
                  <SellSurfboardIcon2 />
                </div>
              </div>
            </NavLink>
          </div>


          {/* <div className='db-fr-elem'>
            <NavLink to={'/chats'} className={'navlink'}>
              <div className='db-fr-content-container'>
                <h4>Chats</h4>
                <div className='db-fr-icon chats-icon'>
                  <ChatsIcon />
                </div>
              </div>
            </NavLink>
          </div> */}

          <div className='db-fr-elem'>
            <NavLink to={'/products'} className={'navlink'}>
              <div className='db-fr-content-container'>
                <h4>Buy Surfboard</h4>
                <div className='db-fr-icon'>
                  <img src={SurfpointLogo} alt='surfpoint.svg' />
                </div>
              </div>
            </NavLink>
          </div>

        </div>
      </div>

      <div className='dashboard-second-row'>
        <div className='db-sr-inner'>
          <div className='db-sr-elems'>

            <div className='db-sr-elem'>
              <h6>Welcome, Surf Legends! 🌊</h6>
              <p>We're beyond grateful for every single user joining us during this exciting beta phase.</p>
              <br />
              <p>
                Your participation means the world to us, and we're committed to  <span>making this platform as rad as possible for you.</span>.
              </p>
              <p><span>Got feedback?</span> We'd love to hear it! Please feel free to request any feature and discuss it in the community.</p>
              <p>Let's ride this wave together and make something epic! 🌊</p>
              <div className='feedback-form-btn-div'>
                <button>
                  <a href='https://insigh.to/b/surfpointio' target='_blank'>Feedback-Form</a>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Dashboard