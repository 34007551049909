
const SellSurfboardIcon2 = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2955_21)">
          <path d="M18.7266 2.79826V1.93148C18.7266 1.3792 18.2788 0.931488 17.7266 0.931488H1.99305C1.44076 0.931488 0.993042 1.3792 0.993042 1.93148V17.665C0.993042 18.2173 1.44076 18.665 1.99305 18.665H2.8598" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M23.0078 22.0684C23.0078 22.6206 22.5601 23.0684 22.0078 23.0684H7.27734C6.72506 23.0684 6.27734 22.6206 6.27734 22.0684V7.33789C6.27734 6.78561 6.72506 6.33789 7.27734 6.33789H22.0078C22.5601 6.33789 23.0078 6.78561 23.0078 7.33789V22.0684Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.6426 18.8741V10.5323" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.4726 14.7031H18.8143" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_2955_21">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>

    </>
  )
}

export default SellSurfboardIcon2