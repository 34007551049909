import './LoginPage.css';
import bannerImg from '../../assets/images/beforeregisterbanner.svg';
import { Login, Logo } from "../../components";
import { NavLink } from 'react-router-dom';

const LoginPage = () => {
  return (
    <>
      <div className='login-page-logo'><Logo /></div>
      <div className='container'>
        <div className='grid grid-two-column'>

          <div className='image-container'>
            <div className='banner-img'>
              <img src={bannerImg} alt='banner.png' />
            </div>
          </div>

          <div className='form-container'>
            <div className='inner-form-container'>
              <h2>Login with your E-Mail</h2>
              {/* <p>Lorem ip Lrdem ips um <br /> dolor sit amet.</p> */}
              <Login />
              <div className='login-page-links'>
                <div className="forget-password-login-page">Forgot your password? Write us: <NavLink to={"/contactus"} target='_blank' >hello@surfpoint.io</NavLink></div>
                <NavLink to={"/signup"}>Registration</NavLink>
              </div>
            </div>
          </div>

        </div>
      </div>



      <div className='mobile-container'>
        <div className='m-c-inner'>
          <div className='m-c-i-heading-container'>
            <h2>Login with your E-Mail</h2>
            {/* <p>Lorem ip Lrdem ips um dolor sit amet.</p> */}
          </div>
          
          <div className='m-c-i-image-container'>
            <div className='banner-img'>
              <img src={bannerImg} alt='banner.png' />
            </div>
          </div>
          <Login />
          <div className='login-page-links'>
            <NavLink to={"/forgotpassword"}>Forgot your password?</NavLink>
            <NavLink to={"/signup"}>Registration</NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage